import styles from './mobileNavBar.module.scss'
import { enableMFAChallengeWidget } from './enableMFAChallengeWidget'

interface ToggleLevel3CallbackParams {
  featureMFA: boolean
  authWidgetConfigData: any
  session: any
  setCallBackUrl: Function
  setActivityName: Function
  setChallengeModalIsOpen: Function
  levelThreeMenuListWrapperRef: any
}

/**
 * Sets a timer to focus on the hamburger close icon after 1 second.
 * This function uses `setTimeout` to delay the focus action.
 * It attempts to find an element with the ID 'hamburgerCloseIcon' and focuses on it if found.
 */
const setTimerOnHamburgerCloseIconFocus = () => {
  setTimeout(() => {
    const hamburgerCloseIcon = document.getElementById(
      'hamburgerCloseIcon'
    ) as HTMLElement
    if (hamburgerCloseIcon) {
      hamburgerCloseIcon.focus()
    }
  }, 1000)
}

/**
 * Updates the text content of specific menu elements based on the event target's inner text.
 *
 * This function performs the following updates:
 * 1. Sets the innerHTML of the element with ID 'activeLevelThreeMenu' to the innerText of the event target.
 * 2. Sets the innerHTML of the element with ID 'level3-backMenu' to the innerText of the element with ID 'activeLevelTwoMenu'.
 *
 * @param {any} e - The event object containing the target element whose innerText will be used to update the menu text.
 */
const updateMenuText = (e: any) => {
  const levelTwoText = document.getElementById('activeLevelThreeMenu') as any
  if (levelTwoText) {
    levelTwoText.innerHTML = e.target.innerText
  }

  const levelOneText = document.getElementById('activeLevelTwoMenu') as any
  const levelThreeText = document.getElementById('level3-backMenu') as any
  if (levelOneText && levelThreeText) {
    levelThreeText.innerHTML = levelOneText.innerText
  }
}

/**
 * Handles the click event for a level 3 menu item.
 *
 * @param e - The event object from the click event.
 * @param levelThreeMenuListWrapper - The wrapper element for the level 3 menu list.
 * @param levelFourMenuListWrapper - The wrapper element for the level 4 menu list.
 * @param isActiveClass - The class name to be added to the active menu item.
 */
const handleLevel3Menu = (
  e: any,
  levelThreeMenuListWrapper: any,
  levelFourMenuListWrapper: any,
  isActiveClass: string
) => {
  const target = e.target
  if (
    target.tagName.toLowerCase() === 'a' &&
    target?.nextElementSibling?.innerHTML
  ) {
    const siblingList = target.nextElementSibling
    siblingList.classList.add(`${styles.documentPages}`)
    e.preventDefault()
    target.classList.add(isActiveClass)
    const cloneSiblingList = siblingList.cloneNode(true)
    levelFourMenuListWrapper.innerHTML = ''
    levelFourMenuListWrapper.append(cloneSiblingList)
    levelThreeMenuListWrapper.style.transform = 'none'
    levelThreeMenuListWrapper.style.display = 'block'
    updateMenuText(e)
    setTimerOnHamburgerCloseIconFocus()
  }
}

/**
 * Callback function to handle the toggle action for level 3 menu items.
 *
 * @param e - The event object.
 * @param params - An object containing various parameters required for the callback.
 * @param params.featureMFA - Feature flag for Multi-Factor Authentication.
 * @param params.authWidgetConfigData - Configuration data for the authentication widget.
 * @param params.session - The current user session.
 * @param params.setCallBackUrl - Function to set the callback URL.
 * @param params.setActivityName - Function to set the activity name.
 * @param params.setChallengeModalIsOpen - Function to set the state of the challenge modal.
 * @param params.levelThreeMenuListWrapperRef - Reference to the level 3 menu list wrapper element.
 */
export const toggleLevel3Callback = (
  e: any,
  params: ToggleLevel3CallbackParams
) => {
  const {
    featureMFA,
    authWidgetConfigData,
    session,
    setCallBackUrl,
    setActivityName,
    setChallengeModalIsOpen,
    levelThreeMenuListWrapperRef,
  } = params

  // Render MFA Challenge Widget
  enableMFAChallengeWidget(
    e,
    featureMFA,
    authWidgetConfigData,
    session,
    setCallBackUrl,
    setActivityName,
    setChallengeModalIsOpen
  )
  // End Render MFA Challenge Widget
  if (typeof window !== 'undefined') {
    const isActiveClass = 'is-active'
    const levelThreeMenuListWrapper =
      levelThreeMenuListWrapperRef.current as any
    const levelFourMenuListWrapper =
      levelThreeMenuListWrapper.querySelector('.subMenuWrapper')

    handleLevel3Menu(
      e,
      levelThreeMenuListWrapper,
      levelFourMenuListWrapper,
      isActiveClass
    )
  }
}
