import { removeUpArrowFromParentMenus } from './HeaderTierTop'
import pageHeaderStyles from '../pageHeader.module.scss'
import styles from '../desktopNavBar.module.scss'
import focusTrap from '@/utils/focus-trap/focus-trap'
import * as TealiumHandlers from '@/modules/tealium/utilities/events/clickHandlers'

interface OpenLoginModalParams {
  setLoginModalIsOpen: (status: boolean) => void
  setOverlayRendered: (status: boolean) => void
  listMainMenu: React.RefObject<any>
  featureMFA: boolean
}

/**
 * Opens the login modal and performs various UI updates.
 *
 * @param {Object} params - The parameters for opening the login modal.
 * @param {Function} params.setLoginModalIsOpen - Function to set the login modal open state.
 * @param {Function} params.setOverlayRendered - Function to set the overlay rendered state.
 * @param {React.RefObject} params.listMainMenu - Reference to the main menu list element.
 * @param {boolean} params.featureMFA - Flag indicating if Multi-Factor Authentication (MFA) is enabled.
 *
 * @returns {void}
 */
export const openLoginModal = ({
  setLoginModalIsOpen,
  setOverlayRendered,
  listMainMenu,
  featureMFA,
}: OpenLoginModalParams) => {
  setLoginModalIsOpen(true)
  const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
  if (subMenu) {
    for (const subMenus of subMenu) {
      subMenus.classList.remove(styles.isVisible)
    }
  }

  const parentMenuList = listMainMenu.current as any
  if (parentMenuList) {
    const menuList = parentMenuList.querySelectorAll('li')
    removeUpArrowFromParentMenus(menuList)
  }

  const showOverlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
  showOverlay.classList.remove(pageHeaderStyles.twoTierDarkMode)
  setOverlayRendered(false)
  const loginModal: any = document.querySelector<HTMLElement>(`.loginModal`)
  loginModal.classList.add(styles.showLoginModal)

  focusTrap('.loginModal')

  if (!featureMFA) {
    const userLoginField = loginModal.querySelector(
      '#input_login_modal_username'
    )
    userLoginField.focus()
  }

  showOverlay.classList.toggle(pageHeaderStyles.twoTierDarkMode)

  TealiumHandlers.displayLoginModalHandler()
}
