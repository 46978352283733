import '@usb-shield/react-grid/dist/library/styles/index.css'
import { createRef, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { signOut, useSession } from 'next-auth/react'
import USBButton from '@usb-shield/react-button'
import { USBColumn } from '@usb-shield/react-grid'
import USBLink from '@usb-shield/react-link'
import pageHeaderStyles from './pageHeader.module.scss'
import styles from './mobileNavBar.module.scss'
import { useRouter } from 'next/router'
import { downloadDocument } from '@/modules/products/ProductOverviewPage/services/downloadDocument.service'
import { showSuccessToast } from '@/utils/toast/toast'
import { PageHeaderMenuItem } from './pageHeader.model'
import Link from 'next/link'
import { useCookies } from 'react-cookie'
import RegionPicker from '@/components/RegionPicker/RegionPicker'
import { InputSearch } from '@/components/InputSearch/InputSearch'
import { useSearchBarUtil } from './util/SearchBarUtil'
import { useQueryClient } from '@tanstack/react-query'
import { convertStringIntoCamelCase } from '@/utils/strings/strings'
import { fetchAccessibleProductLink } from '@/utils/products/global-element-product-access-check'
import { USBIconClose, USBIconBuildings } from '@usb-shield/react-icons'
import ChallengeWidgetModal from '@/modules/challenge-widget/ChallengeWidget'
import { toggleLevel3Callback } from './toggleLevel3Callback';
import { toggleLevel2Callback, escapeKeyPressed, enterKeyPressed, setFocusOnFirstTab, focusOnLevelThree, focusOnLevelTwo, togglebackOneLevelCallback, focusOnLevelZero } from './MobileNavBarUseEffectUtils';

const mobileHeaderNavColumn = {
  span: null,
  spans: {
    xlarge: 16,
    large: 16,
    medium: 8,
    small: 4,
  },
  offset: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  display: 'block',
  padding: 'zero',
  align: 'start',
  justify: 'stretch',
}

export const MobileNavBar = ({
  menuItems,
  imageURL,
  mainmenuId,
  regionOptions,
  isLoginSignUpDisabled,
  isSearchDisabled,
  allProductVersionsInfo,
  authWidgetConfigData,
}: any) => {
  const [isMobileViewport, setIsMobileViewport] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== 'undefined' && window.innerWidth <= 1057) {
        setIsMobileViewport(true);
      } else {
        setIsMobileViewport(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { handleSearchKeyDown } = useSearchBarUtil()
  const { data: session, status } = useSession()
  const queryClient = useQueryClient()
  const menuListWrapperRef = useRef(null)
  const levelOneMenuListWrapperRef = useRef(null)
  const levelTwoMenuListWrapperRef = useRef(null)
  const levelThreeMenuListWrapperRef = useRef(null)
  const menuWrapperRef = useRef(null)
  const backOneLevelRef = useRef(null)
  const [cookies] = useCookies(['locale', 'countryCode'])
  let countryCode = (cookies.countryCode || 'en-us').toLowerCase()
  
  if (countryCode === 'us') {
    countryCode = 'en-us'
  }

  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
  const sandboxProductsExists: boolean = process.env.FEATURE_SANDBOX_PROJECTS === 'true'
  let featureDeveloperAssistant: boolean = process.env.FEATURE_DEVELOPER_ASSISTANT !== 'false'

   // MFA Challenge Widget
   const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
   const [challengeModalIsOpen, setChallengeModalIsOpen] = useState<boolean>(false)
   const [activityName, setActivityName] = useState<string>('')
   const [callBackUrl, setCallBackUrl] = useState<string>('')
   useEffect(() => {}, [challengeModalIsOpen, callBackUrl])
   // End MFA Challenge Widget

  const inSessionMobileMenu = !isElavon ? [
    {
      id: 'credentials-mobile',
      value: 'Credentials',
      url: '/credentials',
    },
    {
      id: 'profile-mobile',
      value: 'Account Profile',
      url: '/profile',
    },
    {
      id: 'support-mobile',
      value: 'Support Tickets',
      url: '/support',
    },
  ] : [
    {
      id: 'dashboard-mobile',
      value: 'Dashboard',
      url: '/dashboard',
    },
    {
      id: 'account-mobile',
      value: 'Account',
      url: '/profile',
    }
  ]

  if (process.env.FEATURE_SUPPORT_TICKET !== 'true') {
    const SUPPORT_ITEM_INDEX = inSessionMobileMenu.findIndex((item: any) => {
      return item.id.toLowerCase() === 'support-mobile'
    })
    
    if (SUPPORT_ITEM_INDEX !== -1) {
      inSessionMobileMenu.splice(SUPPORT_ITEM_INDEX, 1)
    }
  }
  
  if (process.env.FEATURE_DEVELOPER_DASHBOARD !== 'true') {
    const CREDENTIALS_ITEM_INDEX = inSessionMobileMenu.findIndex((item: any) => {
      return item.id.toLowerCase() === 'credentials-mobile'
    })
    
    if (CREDENTIALS_ITEM_INDEX !== -1) {
      inSessionMobileMenu.splice(CREDENTIALS_ITEM_INDEX, 1)
    }
  }

  const router = useRouter()

  const onLogoutClick = async () => {
    await signOut({ callbackUrl: '/', redirect: false })
    showSuccessToast('Logout successful')
    router.push('/').then()
    queryClient.removeQueries()
  }

  const onLoginClick = () => {
    router.push('/login').then()
  }

  let mainMenuRef: any = ''

  mainMenuRef = useRef(mainmenuId?.mainMenuId?.map(() => createRef())) as any

  const hamburgerKeyup = (e: KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()

    // toggle the menu if the enter key is pressed
    if (e.key === 'Enter') {
      toggleMenuCallback()
    }
  }

  const toggleMenuCallback = () => {
    if (typeof window !== 'undefined') {
      const menuWrapper = menuWrapperRef.current as any
      const levelOneMenuListWrapper = levelOneMenuListWrapperRef.current as any
      const levelTwoMenuListWrapper = levelTwoMenuListWrapperRef.current as any
      const levelThreeMenuListWrapper =
        levelThreeMenuListWrapperRef.current as any
      const isVisibleClass = 'isVisible'
      const isActiveClass = 'is-active'
      // Toggle menu wrapper
      menuWrapper.classList.toggle(isVisibleClass)
      menuWrapper.style.display = 'block'
      if (!menuWrapper.classList.contains(isVisibleClass)) {
        levelOneMenuListWrapper.style.display = 'none'
        levelTwoMenuListWrapper.style.display = 'none'
        levelThreeMenuListWrapper.style.display = 'none'
        menuWrapper.style.display = 'none'
        const menuLinks = menuWrapper.querySelectorAll('a')
        for (const menuLink of menuLinks) {
          menuLink.classList.remove(isActiveClass)
        }
        // Enable hamburger icon
        const hamburgerIcon = document.getElementById('sm_menu_ham') as any
        hamburgerIcon.style.display = 'block'
        // Disable hamburger close icon
        const hamburgerCloseIcon = document.getElementById(
          'hamburgerCloseIcon'
        ) as any
        hamburgerCloseIcon.style.display = 'none'
        // Disable the overlay
        const mainMenu = document.getElementById(`showOverlay`) as any
        mainMenu.style.display = 'none'
        mainMenu.classList.remove(pageHeaderStyles.darkMode)
      } else {
        // Disable hamburger icon
        const hamburgerIcon = document.getElementById('sm_menu_ham') as any
        hamburgerIcon.style.display = 'none'
        // Enable hamburger close icon
        const hamburgerCloseIcon = document.getElementById(
          'hamburgerCloseIcon'
        ) as any
        hamburgerCloseIcon.style.display = 'block'
        hamburgerCloseIcon.focus()
        // Show overlay with dark mode
        const mainMenu = document.getElementById(`showOverlay`) as any
        mainMenu.style.display = 'block'
        mainMenu.classList.add(pageHeaderStyles.darkMode)
      }
    }
  }

  let hamburgerCloseIcon: HTMLElement
  if (typeof window !== 'undefined') {
    hamburgerCloseIcon = document.getElementById('hamburgerCloseIcon') as HTMLElement
  }

  const setHamburgerCloseIconFocus = () => {
    hamburgerCloseIcon?.focus()
  }

  const setTimerOnHamburgerCloseIconFocus = () => {
    setTimeout(() => {
      setHamburgerCloseIconFocus()
    }, 1000);
  }

  const removeParentMenuIfNoProductsAvailable = () => {
    // Remove parent menu if no products available
    const getParentMenu: any = document.querySelectorAll<HTMLElement>(`.level3`)
    for(const element of getParentMenu) {
      if(element.innerHTML) {
        // empty condition      
      } else {
        element.closest('li').remove();
      }
    }
  }

  const toggleLevel1Callback = (e: any, id: string, levelRef: any) => {
    removeParentMenuIfNoProductsAvailable()
    if (typeof window !== 'undefined') {
      // browser code
      const isActiveClass = 'is-active'
      const levelOneMenuListWrapper = levelOneMenuListWrapperRef.current as any
      const levelOneLinks = levelRef
      // Toggle submenu wrapper
      const subMenuWrapper =
        levelOneMenuListWrapper.querySelector('.subMenuWrapper')
      const siblingList = levelOneLinks?.current?.nextElementSibling
      if (siblingList) {
        e.preventDefault()
        siblingList.classList.add(isActiveClass)
        const cloneSiblingList = siblingList.cloneNode(true)
        subMenuWrapper.innerHTML = ''
        subMenuWrapper.append(cloneSiblingList)
        levelOneMenuListWrapper.style.transform = 'none'
        levelOneMenuListWrapper.style.display = 'block'
        // Level 1 - Active Menu
        const levelOneText = document.getElementById(
          'activeLevelOneMenu'
        ) as any
        levelOneText.innerHTML = document.getElementById(id)?.innerText
        // Level 2 - Active Menu
        const levelTwoText = document.getElementById('level2-backMenu') as any
        levelTwoText.innerHTML = document.getElementById(id)?.innerText

        setTimerOnHamburgerCloseIconFocus()
      }
    }
  }

  //Back button callback - Level 1 menu
  const handleTogglebackOneLevelCallback = () => {
    togglebackOneLevelCallback(levelOneMenuListWrapperRef, setTimerOnHamburgerCloseIconFocus)
  }

  //Back button callback - Level 2 menu
  const togglebackSecondLevelCallback = () => {
    if (typeof window !== 'undefined') {
      const isActiveClass = 'is-active'
      const parent = levelTwoMenuListWrapperRef.current as any
      parent.style = ''
      parent.previousElementSibling
        .querySelector('.is-active')
        .classList.remove(isActiveClass)

      setTimerOnHamburgerCloseIconFocus()
    }
  }

  //Back button callback - Level 3 menu
  const togglebackThirdLevelCallback = () => {
    if (typeof window !== 'undefined') {
      const isActiveClass = 'is-active'
      const parent = levelThreeMenuListWrapperRef.current as any
      parent.style = ''
      parent.previousElementSibling
        .querySelector('.is-active')
        ?.classList.remove(isActiveClass)
      parent.previousElementSibling
        .querySelector(`ul.${styles.documentPages}`)
        ?.classList.remove(`${styles.documentPages}`)

      setTimerOnHamburgerCloseIconFocus()
    }
  }

  useEffect(() => {
    //called when level 2 menu has any sub-menus
    const element2 = levelTwoMenuListWrapperRef.current as any
    element2.addEventListener('click', (e: any) => toggleLevel3Callback(e, {
      featureMFA,
      authWidgetConfigData,
      session,
      setCallBackUrl,
      setActivityName,
      setChallengeModalIsOpen,
      levelThreeMenuListWrapperRef
    }))

    //called when level 3 menu has any sub-menus
    document.addEventListener('keydown', (event: any) => escapeKeyPressed(event, menuWrapperRef, toggleMenuCallback), false)

    const lastItemFocusOut = (event: any) => {
      setHamburgerCloseIconFocus()
    }

    let levelZeroLastItem: HTMLElement
    let levelOne: HTMLElement = document.getElementById('levelOne') as HTMLElement
    let levelOneLastItem: HTMLElement = levelOne?.nextElementSibling?.nextElementSibling?.firstChild?.lastChild as HTMLElement
    let levelTwo: HTMLElement = document.getElementById('levelTwo') as HTMLElement
    let levelTwoLastItem: HTMLElement = levelTwo?.nextElementSibling?.nextElementSibling?.firstChild?.lastChild as HTMLElement
    let levelThree: HTMLElement | null = document.getElementById('levelThree')
    let levelThreeLastItem: HTMLElement | null = levelThree?.nextElementSibling?.nextElementSibling?.firstChild?.lastChild as HTMLElement

    const focusOnLevelThreeWrapper = () => {
      focusOnLevelThree(
        setFocusOnFirstTab,
        enterKeyPressed,
        handleTogglebackOneLevelCallback,
        togglebackSecondLevelCallback,
        togglebackThirdLevelCallback,
        lastItemFocusOut
      )
    }

    const focusOnLevelTwoWrapper = () => {
      focusOnLevelTwo(
        setFocusOnFirstTab,
        enterKeyPressed,
        handleTogglebackOneLevelCallback,
        togglebackSecondLevelCallback,
        togglebackThirdLevelCallback,
        lastItemFocusOut
      )
    }

    const focusOnLevelOne = () => {
      const levelOne = document.getElementById('levelOne') as HTMLElement
      const levelOneLastItem = levelOne?.nextElementSibling?.nextElementSibling?.firstChild?.lastChild as HTMLElement
      setFocusOnFirstTab(levelOne, levelOneLastItem, { enterKeyPressed: (event: any) => enterKeyPressed(event, handleTogglebackOneLevelCallback, togglebackSecondLevelCallback, togglebackThirdLevelCallback), lastItemFocusOut })
    }

    const focusOnLevelZeroWrapper = () => {
      const levelZero = document.querySelector("#menu-level1") as HTMLElement | null
      const levelZeroLastItem = levelZero?.lastChild?.firstChild as HTMLElement
      if (levelZero) {
        focusOnLevelZero(levelZero, levelZeroLastItem, lastItemFocusOut)
      }
    }

    const hamburgerCloseIconFocusOut = () => {
      let level0, level1, level2, level3 = false;

      const levelThreeMenuListWrapper = levelThreeMenuListWrapperRef.current as any
      level3 = levelThreeMenuListWrapper.style.display === 'block'

      const levelTwoMenuListWrapper = levelTwoMenuListWrapperRef.current as any
      level2 = levelTwoMenuListWrapper.style.display=== 'block'

      const levelOneMenuListWrapper = levelOneMenuListWrapperRef.current as any
      level1 = levelOneMenuListWrapper.style.display === 'block' 

      const menuWrapper = menuWrapperRef.current as any
      level0 = menuWrapper.classList.contains('isVisible')
      const levelZero = document.querySelector("#menu-level1") as HTMLElement | null
      levelZeroLastItem = levelZero?.lastChild?.firstChild as HTMLElement
      
      switch (true) {
        case level3:
          focusOnLevelThreeWrapper()
          break;
          
        case level2:
          focusOnLevelTwoWrapper()
          break;
          
        case level1:
          focusOnLevelOne()
          break;
          
        case level0:
          focusOnLevelZeroWrapper()
          break;
        default:
          break;
      }
    }

    const element1 = levelOneMenuListWrapperRef.current as any
    element1.addEventListener('click', (e: any) => toggleLevel2Callback(e, levelTwoMenuListWrapperRef, setTimerOnHamburgerCloseIconFocus))

    hamburgerCloseIcon?.addEventListener('focusout', hamburgerCloseIconFocusOut, false)

    return () => {
      element1.removeEventListener('click', (e: any) => toggleLevel2Callback(e, levelTwoMenuListWrapperRef, setTimerOnHamburgerCloseIconFocus))
      element2.removeEventListener('click', (e: any) => toggleLevel3Callback(e, {
        featureMFA,
        authWidgetConfigData,
        session,
        setCallBackUrl,
        setActivityName,
        setChallengeModalIsOpen,
        levelThreeMenuListWrapperRef
      }))

      document.removeEventListener('keydown', (event: any) => escapeKeyPressed(event, menuWrapperRef, toggleMenuCallback), false)

      hamburgerCloseIcon?.removeEventListener('focusout', hamburgerCloseIconFocusOut, false)

      levelZeroLastItem?.removeEventListener('focusout', lastItemFocusOut, false)

      levelOne?.removeEventListener('keydown', (event: any) => enterKeyPressed(event, handleTogglebackOneLevelCallback, togglebackSecondLevelCallback, togglebackThirdLevelCallback), false)
      levelOneLastItem?.removeEventListener('focusout', lastItemFocusOut, false)

      levelTwo?.removeEventListener('keydown', (event: any) => enterKeyPressed(event, handleTogglebackOneLevelCallback, togglebackSecondLevelCallback, togglebackThirdLevelCallback), false)
      levelTwoLastItem?.removeEventListener('focusout', lastItemFocusOut, false)

      if (levelThree) {
        levelThree.removeEventListener('keydown', (event: any) => enterKeyPressed(event, handleTogglebackOneLevelCallback, togglebackSecondLevelCallback, togglebackThirdLevelCallback), false)
      }
      if (levelThreeLastItem) {
        levelThreeLastItem.removeEventListener('focusout', lastItemFocusOut, false)
      }
    }
  }, [session])
  let counter = 0;
  let renderMobileMenuItems

  if (menuItems && isMobileViewport) {
    renderMobileMenuItems = menuItems?.map(function (
      firstLevelMenuItem: PageHeaderMenuItem,
      firstLevelItemIndex: number
    ) {
      const eleID = convertStringIntoCamelCase(firstLevelMenuItem?.value)
        return (
          firstLevelMenuItem?.value && <li key={eleID}>
          {firstLevelMenuItem?.fileType == 'download' ? (
            <a
              id={`${eleID}`}
              className={styles.nested}
              ref={mainMenuRef.current[firstLevelItemIndex]}
              key={eleID}
              href={'#toggleLevel1Callback'}
              data-testid={`${eleID}`}
              onClick={async () => {
                if (firstLevelMenuItem.documentPath) {
                  await downloadDocument(
                    firstLevelMenuItem.documentPath,
                    firstLevelMenuItem.value,
                    cookies.locale
                  )
                }
              }}
            >
              {firstLevelMenuItem.value}
            </a>
          ) : (
              <a
                id={`${eleID}`}
                className={styles.nested}
                ref={mainMenuRef.current[firstLevelItemIndex]}
                key={eleID}
                href={`${firstLevelMenuItem?.url}` || '#'}
                data-testid={`${eleID}`}
                onClick={(e: any) => {
                  toggleLevel1Callback(
                    e,
                    firstLevelMenuItem?.id,
                    mainMenuRef.current[firstLevelItemIndex]
                  )
                }}
              >
                {firstLevelMenuItem.value}
              </a>
          )}
          {firstLevelMenuItem?.hasSubMenus && (
            <ul className={`${styles.subMenu} ${styles.level2}`}>
              {firstLevelMenuItem?.hasSubMenus &&
                firstLevelMenuItem?.menuItems?.items?.map(function (
                  secondLevelMenuItem: PageHeaderMenuItem,
                  secondLevelItemIndex: any
                ) {
                  return (
                    <li key={secondLevelMenuItem.id}>
                      <Link href={`${secondLevelMenuItem.url}` || '#'}>
                        <a className={styles.nested} href={`${secondLevelMenuItem.url}` || '#'} data-testid={`secondLevel-${secondLevelMenuItem.id}`} id={`secondLevel-${secondLevelMenuItem.id}`}>
                          {secondLevelMenuItem.value}
                        </a>
                      </Link>
                      {secondLevelMenuItem?.hasSubMenus && (
                        <ul className={`${styles.subMenu} ${styles.level3} level3`}>
                          {secondLevelMenuItem?.menuItems?.items?.map(function (
                              thirdLevelMenuItem: PageHeaderMenuItem,
                              thirdLevelItemIndex: any
                            ) {
                              const PRODUCT_SUITE_PATH = 'product-suites'
                              const superChild = thirdLevelMenuItem?.url?.split('/');
                              const superChildPathDepth = superChild?.length ?? 0;
                              const superChildVersion = superChild ? superChild[superChild.length - 1] : '';
                              const superChildProductSlug = superChild ? superChild[superChild.length - 2] : '';
                              let superChildProductLink = thirdLevelMenuItem?.url
                              if(!isElavon) {
                                if (thirdLevelMenuItem.value && superChildPathDepth - 1 > 2 && superChildProductSlug !== PRODUCT_SUITE_PATH) {
                                  superChildProductLink = fetchAccessibleProductLink(superChildVersion, superChildProductSlug, allProductVersionsInfo, countryCode);
                                }
                              }
                              let isProductSuiteProductAvailable: any = 0
                              let isProductSuiteProductClassName = ''
                                
                              thirdLevelMenuItem?.menuItems?.items?.map(
                                function (
                                  fourthLevelMenuItem: PageHeaderMenuItem
                                ) {
                                  const subChild = fourthLevelMenuItem?.url?.split('/');
                                  const subChildPathDepth = subChild?.length || 0;
                                  const subChildVersion = subChild ? subChild[subChild.length - 1] : '';
                                  const subChildProductSlug = subChild ? subChild[subChild.length - 2] : '';
                                  let subChildProductLink = fourthLevelMenuItem?.url;
                                  if(!isElavon) {
                                    if (fourthLevelMenuItem?.value && subChildPathDepth == 4) {
                                      subChildProductLink = fetchAccessibleProductLink(subChildVersion, subChildProductSlug, allProductVersionsInfo, countryCode);
                                      if (subChildProductLink) {
                                        isProductSuiteProductAvailable = parseInt(isProductSuiteProductAvailable) + 1
                                      }
                                    }
                                  }
                              })
                              isProductSuiteProductClassName = superChildProductSlug !== PRODUCT_SUITE_PATH ? 'emptyProductsForProductSuite' : ''
                              
                              if(superChildProductLink) {
                                const superChild = superChildProductLink?.split('/')

                                if (superChild[2] === 'us') {
                                  superChild.splice(2, 1)
                                  superChildProductLink = superChild.join('/')
                                }

                              return (
                                <li key={thirdLevelMenuItem.id}>
                                  <a className={styles.nested} href={`${superChildProductLink}` || '#'} data-testid={`thirdLevel-${thirdLevelMenuItem.id}`}>
                                    {thirdLevelMenuItem.value}
                                  </a>
                                  {/* Product Document Page */}
                                  {thirdLevelMenuItem?.childPages && (
                                    <ul
                                      className={`${styles.childPages} ${styles.level3} ${superChildProductSlug} ${superChildProductSlug == 'product-suites' ? isProductSuiteProductClassName : ''}`}
                                    >
                                      {superChildProductLink != '/products' &&
                                        thirdLevelMenuItem?.childPages != '' && (
                                          <li
                                            className={styles.greatGrandSubMenu}
                                            key={`productOverview-${thirdLevelItemIndex}`}
                                          >
                                            <Link
                                              href={
                                                `${superChildProductLink}` ||
                                                '#'
                                              }
                                            >
                                              <a id='mobile-product-overview-document' data-testid='mobile-product-overview-document' className={styles.nested} href={
                                                `${superChildProductLink}` ||
                                                '#'
                                              }>
                                                Product Overview
                                              </a>
                                            </Link>
                                          </li>
                                        )}
                                      {thirdLevelMenuItem?.childPages?.map(
                                          function (
                                            productDocumentPage: PageHeaderMenuItem
                                          ) {
                                            const eleID = convertStringIntoCamelCase(productDocumentPage?.value)
                                            const nameConvert = convertStringIntoCamelCase(thirdLevelMenuItem.value)
                                            counter++ 
                                            return (
                                              <li
                                                className={
                                                  styles.greatGrandSubMenu
                                                }
                                                key={`${eleID}-${counter}`}
                                              >
                                                <Link
                                                  href={
                                                    `${productDocumentPage.url}` ||
                                                    '#'
                                                  }
                                                >
                                                  <a className={styles.nested} data-testid={`mobile-${nameConvert}-${eleID}`} href={
                                                    `${productDocumentPage.url}` ||
                                                    '#'
                                                  }>
                                                    {productDocumentPage.value}
                                                  </a>
                                                </Link>
                                              </li>
                                            )
                                          }
                                        )}
                                    </ul>
                                  )}
                                  {/* Product Sub Menus */}
                                  {thirdLevelMenuItem?.hasSubMenus && (
                                    <ul className={`${styles.subMenu}  ${superChildProductSlug}`}>
                                      {thirdLevelMenuItem?.hasSubMenus &&
                                        thirdLevelMenuItem?.menuItems?.items?.map(
                                          function (
                                            fourthLevelMenuItem: PageHeaderMenuItem,
                                            fourthLevelItemIndex: number
                                          ) {
                                            const subChild = fourthLevelMenuItem?.url?.split('/');
                                            const subChildPathDepth = subChild?.length ?? 0;
                                            const subChildVersion = subChild ? subChild[subChild.length - 1] : '';
                                            const subChildProductSlug = subChild ? subChild[subChild.length - 2] : '';
                                            let subChildProductLink = fourthLevelMenuItem?.url;
                                            if(!isElavon) {
                                              if (fourthLevelMenuItem?.value && subChildPathDepth - 1 > 2) {
                                                subChildProductLink = fetchAccessibleProductLink(subChildVersion, subChildProductSlug, allProductVersionsInfo, countryCode);
                                              }
                                            }
                                            if(subChildProductLink) {
                                            return (
                                              <li
                                                className={
                                                  styles.greatGrandSubMenu
                                                }
                                                key={fourthLevelItemIndex}
                                              >
                                                  <a className={styles.nested} href={
                                                    `${subChildProductLink}` ||
                                                    '#'
                                                  }>
                                                    {fourthLevelMenuItem.value}
                                                  </a>
                                                
                                                {/* Suite Product Document Page */}
                                                {fourthLevelMenuItem?.childPages && (
                                                  <ul
                                                    className={`${styles.childPages} ${styles.level4} ${styles.noArrow}`}
                                                  >
                                                    <li
                                                      className={
                                                        styles.greatGrandSubMenu
                                                      }
                                                      key={`productOverview-${fourthLevelItemIndex}`}
                                                    >
                                                      
                                                        <a
                                                          className={
                                                            styles.nested
                                                          }
                                                          href={
                                                            `${subChildProductLink}` ||
                                                            '#'
                                                          }
                                                        >
                                                          Product Overview
                                                        </a>
                                                      
                                                    </li>
                                                    {fourthLevelMenuItem?.childPages?.map(
                                                        function (
                                                          productDocumentPage: PageHeaderMenuItem,
                                                          productDocumentPageIndex: number
                                                        ) {
                                                          return (
                                                            <li
                                                              className={
                                                                styles.greatGrandSubMenu
                                                              }
                                                              key={
                                                                productDocumentPageIndex
                                                              }
                                                            >
                                                              
                                                                <a
                                                                  className={
                                                                    styles.nested
                                                                  }
                                                                  href={
                                                                    `${productDocumentPage.url}` ||
                                                                    '#'
                                                                  }

                                                                >
                                                                  {
                                                                    productDocumentPage.value
                                                                  }
                                                                </a>
                                                              
                                                            </li>
                                                          )
                                                        }
                                                      )}
                                                  </ul>
                                                )}
                                              </li>
                                            )
                                            }
                                          }
                                        )}
                                    </ul>
                                  )}
                                </li>
                              )
                              }
                            })}
                        </ul>
                      )}
                    </li>
                  )
                })}
            </ul>
          )}
        </li>
      )
    })
  }
  // This will be removed once AEM integration is in place
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const handleMenuOpenCallback = () => {
    setIsMenuOpen(true)
  }

  const handleMenuCloseCallback = () => {
    setIsMenuOpen(false)
  }

  return (
    <>
      {/* render MFA Challenge widget modal */}
      {challengeModalIsOpen && (
        <ChallengeWidgetModal
          callBackUrl={callBackUrl}
          challengeModalIsOpen={challengeModalIsOpen}
          setChallengeModalIsOpen={setChallengeModalIsOpen}
          authWidgetConfigData={authWidgetConfigData}
          activityName={activityName}
        ></ChallengeWidgetModal>
      )}
      <div id="showOverlay" className={styles.showOverlay}></div>
      <USBColumn
        layoutOpts={mobileHeaderNavColumn}
        addClasses={styles.fullWidth}
      >
        <div className={styles.pageHeader}>
          <nav>
            <div className={process.env.SITE_BRAND.toLowerCase() === 'elavon' ? styles.headerBar : styles.twoTierHeaderBar}>
              <button
                id="sm_menu_ham"
                className={styles.hamburgerIcon}
                data-testid="sm_menu_ham"
                tabIndex={0}
                onKeyUp={(e: any) => hamburgerKeyup(e)}
                onClick={toggleMenuCallback}
                aria-label="Open left side menu"
              >
                <svg
                  id="svg-692205d2-4c90-4f66-afd9-87504749e1e0"
                  aria-hidden="true"
                  viewBox="0 0 20 20"
                  width="30"
                  height="30"
                  className="single"
                  focusable="false"
                >
                  <path
                    id="pathSVG-692205d2-4c90-4f66-afd9-87504749e1e0"
                    d="M19 4.75H1v-1.5h18zm0 10.5H1v1.5h18zm0-6H1v1.5h18z"
                  />
                </svg>
              </button>
              <button
                id="hamburgerCloseIcon"
                className={styles.hamburgerCloseIcon}
                data-testid="hamburgerCloseIcon"
                tabIndex={0}
                onKeyUp={(e: any) => hamburgerKeyup(e)}
                onClick={toggleMenuCallback}
                aria-label="Close left side menu"
              >
                {!isElavon ? (<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M15.3759 7.99989C15.3759 10.9951 13.5703 13.6949 10.8022 14.8389C8.03405 15.9828 4.8493 15.3453 2.73481 13.2239C0.620331 11.1026 -0.00684422 7.91577 1.1461 5.15137C2.29905 2.38696 5.00471 0.590178 7.9999 0.599893C12.085 0.604302 15.3955 3.91481 15.3999 7.99989H15.3759ZM11.1999 5.59989L8.7999 7.99989L11.1999 10.3999L10.3999 11.1999L7.9999 8.79989L5.5999 11.1999L4.7999 10.3999L7.1999 7.99989L4.7999 5.59989L5.5999 4.79989L7.9999 7.19989L10.3999 4.79989L11.1999 5.59989ZM10.343 2.26958C12.6627 3.2278 14.1759 5.49005 14.1759 7.99989H14.1999C14.1955 11.4222 11.4222 14.1955 7.9999 14.1999C5.49007 14.2096 3.22197 12.7052 2.25476 10.3892C1.28755 8.07321 1.81206 5.40255 3.58334 3.62439C5.35463 1.84622 8.02323 1.31136 10.343 2.26958Z" fill="#2D2D3E"/>
                              </svg>) : (<USBIconClose
                                          colorVariant='grey80'
                                          size={24}
                                          addClasses={styles.profileIcon} />)}
              </button>
              <div className={isElavon ? styles.logoAndTaglineContainer : styles.twoTierLogoAndTaglineContainer}>
                <USBLink href={'/'} addClasses={styles.profileLink}>
                  <img
                    src={imageURL}
                    alt={process.env.SITE_BRAND + ' logo'}
                  />
                </USBLink>
              </div>
              <div className={styles.navButton}>
                {status !== 'loading' && !session && !isLoginSignUpDisabled && (
                  <USBLink
                    href="/login"
                    addClasses={isElavon ? styles.loginLink : styles.twoTierLoginLink}
                    variant="loud"
                    size="default"
                    handleClick={onLoginClick}
                    dataTestId="login-link"
                  >
                    Login
                  </USBLink>
                )}
              </div>
            </div>
            <div className={styles.menuWrapper} ref={menuWrapperRef}>
              <div className={styles.listWrapper} ref={menuListWrapperRef}>
                {!isSearchDisabled && (
                  <InputSearch
                    inputID="edp_search_input_mobile"
                    inputName="query_string_mobile"
                    handleSearchKeyDown={handleSearchKeyDown}
                  />
                )}
                <ul id="menu-level1" className={`${styles.menu} ${styles.level1}`}>
                    {renderMobileMenuItems}
                    {status !== 'loading' &&
                      session &&
                      inSessionMobileMenu.map((item, i) => (
                        <li key={item.id} className={styles.inSessionMobileMenu}>
                          {item.value == 'Credentials' ? (
                            sandboxProductsExists && (
                                <a
                                  id={`${item.id}`}
                                  className={styles.nested}
                                  key={item.id}
                                  href={`${item.url}` || '#'}
                                >
                                  {item.value}
                                </a>
                            )
                          ) : (
                              <a
                                id={`${item.id}`}
                                className={styles.nested}
                                key={item.id}
                                href={`${item.url}` || '#'}
                              >
                                {item.value}
                              </a>
                          )}
                        </li>
                      ))}
                    {featureDeveloperAssistant &&  (
                      <li className={styles.inSessionMobileMenu}>
                            <a href={'/'} className={`${styles.devAssistantName} ${styles.devAssistantLink}`} data-testid={'developer-assistant'}>
                              <img src={"/header/developer-assistant-mobile.png"} alt={'Developer Assistant'} /> <span>Developer Assistant</span>
                            </a>
                      </li>                      
                    )}
                    {process.env.FEATURE_GEOLOCATION && (
                      <li className={styles.inSessionMobileMenu}>
                        <RegionPicker
                          options={regionOptions}
                          menuIsOpen={isMenuOpen}
                          handleMenuOpenCallback={handleMenuOpenCallback}
                          handleMenuCloseCallback={handleMenuCloseCallback}
                          classNamePrefix={'mobileFilter'}
                          selectorStyles={{}} // Add appropriate styles here
                        />
                      </li>
                    )}
                    {status !== 'loading' && session && (
                      <li className={styles.inSessionMobileMenu}>
                        <USBButton
                          ctaStyle="standard"
                          emphasis="minimal" 
                          size="medium"
                          handleClick={onLogoutClick}
                          addClasses={styles.logoutBtn}
                          dataTestId="onLogoutClick"
                        >
                          <USBIconBuildings
                            colorVariant='grey80'
                            size={24}
                            addClasses={styles.profileIcon} />Log Out
                        </USBButton>
                      </li>
                    )}
                </ul>
              </div>
              <div
                className={styles.listWrapper}
                ref={levelOneMenuListWrapperRef}
              >
                <button
                  id="levelOne"
                  className={`${styles.backOneLevel} ${styles.levelOne}`}
                  ref={backOneLevelRef}
                  data-testid="togglebackOneLevelCallback"
                  onClick={handleTogglebackOneLevelCallback}
                  tabIndex={0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                  </svg>
                  <span id="level1-backMenu">Main Menu</span>
                </button>
                <div
                  id="activeLevelOneMenu"
                  className={styles.activeLevelOneMenu}
                ></div>
                <div
                  className={`${styles.subMenuWrapper} subMenuWrapper`}
                ></div>
              </div>
              <div
                className={styles.listWrapper}
                ref={levelTwoMenuListWrapperRef}
              >
                <button
                  id="levelTwo"
                  className={`${styles.backOneLevel} ${styles.levelTwo}`}
                  ref={backOneLevelRef}
                  data-testid="togglebackSecondLevelCallback"
                  onClick={togglebackSecondLevelCallback}
                  tabIndex={0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                  </svg>
                  <span id="level2-backMenu">Back</span>
                </button>
                <div
                  id="activeLevelTwoMenu"
                  className={styles.activeLevelTwoMenu}
                ></div>
                <div
                  className={`${styles.subMenuWrapper} subMenuWrapper`}
                ></div>
              </div>
              <div
                className={styles.listWrapper}
                ref={levelThreeMenuListWrapperRef}
              >
                <button
                  id="levelThree"
                  className={`${styles.backOneLevel} ${styles.levelThree}`}
                  ref={backOneLevelRef}
                  data-testid="togglebackThirdLevelCallback"
                  onClick={togglebackThirdLevelCallback}
                  tabIndex={0}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
                  </svg>
                  <span id="level3-backMenu">Back</span>
                </button>
                <div
                  id="activeLevelThreeMenu"
                  className={styles.activeLevelThreeMenu}
                ></div>
                <div
                  className={`${styles.subMenuWrapper} subMenuWrapper`}
                ></div>
              </div>
            </div>
          </nav>
        </div>
      </USBColumn>
    </>
  )
}
