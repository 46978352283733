// CloseLoginModal.tsx
import pageHeaderStyles from '../pageHeader.module.scss'
import styles from '../desktopNavBar.module.scss'
import { focusLoginButtonIfModalShown } from './HeaderTierTop'

interface CloseLoginModalParams {
  loginButtonRef: React.RefObject<any>
  setOverlayRendered: (status: boolean) => void
}

/**
 * Closes the login modal and removes the overlay.
 *
 * @param {Object} params - The parameters for closing the login modal.
 * @param {HTMLElement} params.loginButtonRef - The reference to the login button element.
 * @param {Function} params.setOverlayRendered - The function to set the overlay rendered state.
 *
 * This function performs the following actions:
 * 1. Selects the login modal element and focuses the login button if the modal is shown.
 * 2. Removes the CSS class that shows the login modal.
 * 3. Selects the overlay element and removes the CSS class for dark mode.
 * 4. Sets the overlay rendered state to false.
 */
export const closeLoginModal = ({
  loginButtonRef,
  setOverlayRendered,
}: CloseLoginModalParams) => {
  const loginModal: any = document.querySelector<HTMLElement>(`.loginModal`)
  focusLoginButtonIfModalShown(loginModal, loginButtonRef)

  loginModal?.classList?.remove(styles.showLoginModal)
  const showOverlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
  showOverlay?.classList?.remove(pageHeaderStyles.twoTierDarkMode)
  setOverlayRendered(false)
}
