interface FocusTabCallbacks {
  enterKeyPressed: (event: any) => void
  lastItemFocusOut: (event: any) => void
}

/**
 * Toggles the visibility of a level 2 menu and updates the level 3 menu content.
 *
 * @param e - The event object triggered by the user interaction.
 * @param levelTwoMenuListWrapperRef - A reference to the level 2 menu list wrapper element.
 * @param setTimerOnHamburgerCloseIconFocus - A callback function to set a timer on the hamburger close icon focus.
 *
 * This function performs the following actions:
 * - Checks if the window object is defined.
 * - Retrieves the target element from the event object.
 * - If the target element is an anchor tag with a non-empty next sibling element, it prevents the default action.
 * - Adds an 'is-active' class to the target element.
 * - Clones the sibling list element and appends it to the level 3 menu list wrapper.
 * - Updates the level 2 menu list wrapper's styles to make it visible.
 * - Updates the inner HTML of the element with ID 'activeLevelTwoMenu' to the target element's inner text.
 * - Removes any product suite links if no products are available.
 * - Calls the `setTimerOnHamburgerCloseIconFocus` callback function.
 */
export const toggleLevel2Callback = (
  e: any,
  levelTwoMenuListWrapperRef: React.RefObject<any>,
  setTimerOnHamburgerCloseIconFocus: () => void
) => {
  if (typeof window !== 'undefined') {
    const isActiveClass = 'is-active'
    const levelTwoMenuListWrapper = levelTwoMenuListWrapperRef.current as any
    const levelThreeMenuListWrapper =
      levelTwoMenuListWrapper?.querySelector('.subMenuWrapper')
    const target = e.target
    console.log('Target:', target) // Debug log
    if (
      target.tagName.toLowerCase() === 'a' &&
      target?.nextElementSibling?.innerHTML
    ) {
      const siblingList = target.nextElementSibling
      e.preventDefault()
      target.classList.add(isActiveClass)
      const cloneSiblingList = siblingList.cloneNode(true)
      if (levelThreeMenuListWrapper) {
        levelThreeMenuListWrapper.innerHTML = ''
        levelThreeMenuListWrapper.append(cloneSiblingList)
      }
      levelTwoMenuListWrapper.style.transform = 'none'
      levelTwoMenuListWrapper.style.display = 'block'
      const levelOneText = document.getElementById('activeLevelTwoMenu') as any
      levelOneText.innerHTML = e.target.innerText

      // Remove productSuite link if no products available
      const getProductSuiteList: any = document.querySelectorAll<HTMLElement>(
        `.emptyProductsForProductSuite`
      )
      for (const element of getProductSuiteList) {
        const closestLi = element?.closest('li')
        if (closestLi) {
          closestLi.remove()
        }
      }

      setTimerOnHamburgerCloseIconFocus()
    }
  }
}

/**
 * Handles the 'Escape' key press event to toggle the visibility of a menu.
 *
 * @param event - The keyboard event triggered by the user.
 * @param menuWrapperRef - A reference to the menu wrapper element.
 * @param toggleMenuCallback - A callback function to toggle the menu visibility.
 */
export const escapeKeyPressed = (
  event: any,
  menuWrapperRef: React.RefObject<any>,
  toggleMenuCallback: () => void
) => {
  if (event.key === 'Escape') {
    const menuWrapper = menuWrapperRef.current as any
    if (menuWrapper?.classList.contains('isVisible')) {
      toggleMenuCallback()
    }
  }
}

/**
 * Handles the 'Enter' key press event and triggers the appropriate callback based on the target element's id.
 *
 * @param event - The keyboard event object.
 * @param togglebackOneLevelCallback - Callback function to be called when the target element's id is 'levelOne'.
 * @param togglebackSecondLevelCallback - Callback function to be called when the target element's id is 'levelTwo'.
 * @param togglebackThirdLevelCallback - Callback function to be called when the target element's id is 'levelThree'.
 */
export const enterKeyPressed = (
  event: any,
  togglebackOneLevelCallback: () => void,
  togglebackSecondLevelCallback: () => void,
  togglebackThirdLevelCallback: () => void
) => {
  if (event.key === 'Enter') {
    switch (event.target.id) {
      case 'levelOne':
        togglebackOneLevelCallback()
        break
      case 'levelTwo':
        togglebackSecondLevelCallback()
        break
      case 'levelThree':
        togglebackThirdLevelCallback()
        break
      default:
        break
    }
  }
}

/**
 * Sets focus on the first tab element and attaches event listeners for keydown and focusout events.
 *
 * @param {HTMLElement} level - The element representing the first tab level.
 * @param {HTMLElement} levelLastItem - The element representing the last item in the tab level.
 * @param {FocusTabCallbacks} callbacks - An object containing callback functions for handling keydown and focusout events.
 * @param {Function} callbacks.enterKeyPressed - Callback function to handle the 'enter' key press event.
 * @param {Function} callbacks.lastItemFocusOut - Callback function to handle the focus out event on the last item.
 */
export const setFocusOnFirstTab = (
  level: any,
  levelLastItem: any,
  callbacks: FocusTabCallbacks
) => {
  level?.setAttribute('tabindex', '0')
  level?.focus()
  level?.addEventListener('keydown', callbacks.enterKeyPressed, false)
  levelLastItem.addEventListener('focusout', callbacks.lastItemFocusOut, false)
  return
}

/**
 * Sets focus on the third level of the navigation menu and handles key press events.
 *
 * @param setFocusOnFirstTab - Function to set focus on the first tab.
 * @param enterKeyPressed - Function to handle the enter key press event.
 * @param togglebackOneLevelCallback - Callback to toggle back one level.
 * @param togglebackSecondLevelCallback - Callback to toggle back two levels.
 * @param togglebackThirdLevelCallback - Callback to toggle back three levels.
 * @param lastItemFocusOut - Function to handle focus out event on the last item.
 */
export const focusOnLevelThree = (
  setFocusOnFirstTab: (
    level: any,
    levelLastItem: any,
    callbacks: FocusTabCallbacks
  ) => void,
  enterKeyPressed: (
    event: any,
    togglebackOneLevelCallback: () => void,
    togglebackSecondLevelCallback: () => void,
    togglebackThirdLevelCallback: () => void
  ) => void,
  togglebackOneLevelCallback: () => void,
  togglebackSecondLevelCallback: () => void,
  togglebackThirdLevelCallback: () => void,
  lastItemFocusOut: (event: any) => void
) => {
  let levelThree = document.getElementById('levelThree') as HTMLElement
  let levelThreeLastItem = levelThree?.nextElementSibling?.nextElementSibling
    ?.firstChild?.lastChild as HTMLElement
  setFocusOnFirstTab(levelThree, levelThreeLastItem, {
    enterKeyPressed: (event: any) =>
      enterKeyPressed(
        event,
        togglebackOneLevelCallback,
        togglebackSecondLevelCallback,
        togglebackThirdLevelCallback
      ),
    lastItemFocusOut,
  })
}

/**
 * Sets focus on the second level navigation tab and defines the behavior for key press and focus out events.
 *
 * @param setFocusOnFirstTab - Function to set focus on the first tab of a given level.
 * @param enterKeyPressed - Function to handle the enter key press event.
 * @param togglebackOneLevelCallback - Callback function to toggle back one level.
 * @param togglebackSecondLevelCallback - Callback function to toggle back two levels.
 * @param togglebackThirdLevelCallback - Callback function to toggle back three levels.
 * @param lastItemFocusOut - Function to handle the focus out event on the last item.
 */
export const focusOnLevelTwo = (
  setFocusOnFirstTab: (
    level: any,
    levelLastItem: any,
    callbacks: FocusTabCallbacks
  ) => void,
  enterKeyPressed: (
    event: any,
    togglebackOneLevelCallback: () => void,
    togglebackSecondLevelCallback: () => void,
    togglebackThirdLevelCallback: () => void
  ) => void,
  togglebackOneLevelCallback: () => void,
  togglebackSecondLevelCallback: () => void,
  togglebackThirdLevelCallback: () => void,
  lastItemFocusOut: (event: any) => void
) => {
  const levelTwo = document.getElementById('levelTwo') as HTMLElement
  const levelTwoLastItem = levelTwo?.nextElementSibling?.nextElementSibling
    ?.firstChild?.lastChild as HTMLElement
  setFocusOnFirstTab(levelTwo, levelTwoLastItem, {
    enterKeyPressed: (event: any) =>
      enterKeyPressed(
        event,
        togglebackOneLevelCallback,
        togglebackSecondLevelCallback,
        togglebackThirdLevelCallback
      ),
    lastItemFocusOut,
  })
}

/**
 * Toggles back one level in the mobile navigation menu.
 *
 * This function is used to reset the styles and active state of the level one menu list wrapper
 * and its child elements. It also sets a timer on the hamburger close icon focus.
 *
 * @param levelOneMenuListWrapperRef - A reference to the level one menu list wrapper element.
 * @param setTimerOnHamburgerCloseIconFocus - A callback function to set a timer on the hamburger close icon focus.
 */
export const togglebackOneLevelCallback = (
  levelOneMenuListWrapperRef: React.RefObject<any>,
  setTimerOnHamburgerCloseIconFocus: () => void
) => {
  if (typeof window !== 'undefined') {
    const isActiveClass = 'is-active'
    const parent = levelOneMenuListWrapperRef.current as any
    if (parent) {
      parent.style = ''
      const activeElement =
        parent.previousElementSibling?.querySelector('.is-active')
      if (activeElement) {
        activeElement.classList.remove(isActiveClass)
      }

      setTimerOnHamburgerCloseIconFocus()
    }
  }
}

/**
 * Focuses on the appropriate element based on the site brand and sets up an event listener for focus out.
 *
 * @param {HTMLElement} levelZero - The root element of the navigation level zero.
 * @param {HTMLElement} levelZeroLastItem - The last item in the navigation level zero.
 * @param {(event: any) => void} lastItemFocusOut - The callback function to be called when the last item loses focus.
 *
 * If the site brand is 'elavon', it focuses on the search input element with the ID 'edp_search_input_mobile'.
 * Otherwise, it focuses on the first child element of the level zero element.
 * Additionally, it adds a 'focusout' event listener to the last item in the level zero navigation.
 */
export const focusOnLevelZero = (
  levelZero: HTMLElement,
  levelZeroLastItem: HTMLElement,
  lastItemFocusOut: (event: any) => void
) => {
  if (process.env.SITE_BRAND === 'elavon') {
    let inputSearch = document.getElementById('edp_search_input_mobile')
    inputSearch?.focus()
  } else {
    const levelZeroFirstItem = levelZero?.firstChild?.firstChild as HTMLElement
    levelZeroFirstItem?.focus()
  }
  levelZeroLastItem.addEventListener('focusout', lastItemFocusOut, false)
}
