export const fetchProductVersions = (productInfo: any) => {
  const getProducts: any = productInfo?.data[0]?.products;
  let userSessionInfo = productInfo?.data[0]?.restrictedPrivateProducts ? productInfo.data[0].restrictedPrivateProducts : {}
  const allProductLatestVersionInfo: any = []
  const nonRestrictedPrivateProductAllVersion: any = []
  const restrictedPrivateProductAllVersion: any = []

  for (const key in getProducts) {
    if (getProducts.hasOwnProperty(key)) {
      const restrictedPrivateProductDomainSpecific: any = {}
      const nonRestrictedPrivateProductDomainSpecific: any = {}
      for (const domainKey in getProducts[key].displayVersionList) {
        const versionList: any = getProducts[key].displayVersionList[domainKey].sort((a: any, b: any) => {
          const key1: any = Object.keys(a)[0]
          const key2: any = Object.keys(b)[0]

          if (key1 > key2) return 1
          if (key1 < key2) return -1
          return 0
        })
        if (domainKey && getProducts[key].displayVersionList.hasOwnProperty(domainKey)) {
          versionList.map((pdAllVersions: any, pdAllKey: any) => {
              const restrictedPrivateProductVersionSpecific: any = []
              const nonRestrictedPrivateProductVersionSpecific: any = []
              for (const pv in pdAllVersions) {
                if (pdAllVersions.hasOwnProperty(pv)) {
                  const url: string = domainKey !== 'en-us'
                    ? '/products/' + [domainKey] + '/' + [key] + '/' + [pv]
                    : '/products/' + [key] + '/' + [pv]

                  if (pdAllVersions[pv].visibilityLevel == 'restricted-private') {
                    restrictedPrivateProductVersionSpecific[pv] = url
                  }
                  
                  if (pdAllVersions[pv].visibilityLevel != 'restricted-private') {
                    nonRestrictedPrivateProductVersionSpecific[pv] = url
                  }
                }
              }
              restrictedPrivateProductDomainSpecific[domainKey] = restrictedPrivateProductVersionSpecific
              nonRestrictedPrivateProductDomainSpecific[domainKey] = nonRestrictedPrivateProductVersionSpecific
            }
          )
          if (getProducts[key].latestVersion[domainKey]) {
            allProductLatestVersionInfo[key] = getProducts[key].latestVersion[domainKey]
          }
        }
        restrictedPrivateProductAllVersion[key] = restrictedPrivateProductDomainSpecific
        nonRestrictedPrivateProductAllVersion[key] = nonRestrictedPrivateProductDomainSpecific
      }
    }
  }

  // It will be removed once real time data getting replaced
  let allProductVersionsInfo: any = {
    restrictedPrivateProductAllVersion: restrictedPrivateProductAllVersion,
    allProductLatestVersionInfo: allProductLatestVersionInfo,
    nonRestrictedPrivateProductAllVersion: nonRestrictedPrivateProductAllVersion,
    userSessionInfo: userSessionInfo,
  }
  return allProductVersionsInfo
}
