//Shield components
//import USBTextInput from '@usb-shield/react-forms-input-text'
//import USBPasswordInput from '@usb-inner-src/react-forms-input-password'
import USBButton from '@usb-shield/react-button'
import USBLink from '@usb-shield/react-link'
import USBNotification from '@usb-shield/react-notification'
import { useRouter } from 'next/router'
import { getSession, signIn, SignInResponse } from 'next-auth/react'
import { ShieldFieldStatus } from '@/models/shield.model'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { Field, Form, Formik, FormikProps } from 'formik'
import ReactLoadingInline from '@/components/ReactLoaders/ReactLoadingInline'
import loginStyles from '@/components/Login/login.module.scss'
import { loginPageData as pageData } from '@/components/Login/login.content'
import { showSuccessToast } from '@/utils/toast/toast'
import parse from 'html-react-parser'
import { FormValues } from './login.model'
import * as Tealium from '@/modules/tealium/Tealium'

//import USBTextInput from '@usb-shield/react-forms-input-text'
const USBTextInput: any = dynamic(
  () => import('@usb-shield/react-forms-input-text'),
  {
    ssr: false,
  }
)
//import USBPasswordInput from '@usb-inner-src/react-forms-input-password'
import dynamic from 'next/dynamic'
const USBPasswordInput: any = dynamic(
  () => import('@usb-inner-src/react-forms-input-password'),
  {
    ssr: false,
  }
)

function enableErrorNotification() {
  document
    .getElementById('register_fail')
    ?.setAttribute('style', 'display: block')
}

export const Login = ({
  overlayDarkModeClass,
  loginModal,
}: {
  overlayDarkModeClass?: string
  loginModal?: boolean
}) => {
  const featureRegistrationOptimization: boolean =
    process.env.FEATURE_REGISTRATION_OPTIMIZATION !== 'false'
  const userLoginWrapper = useRef<HTMLDivElement | null>(null)
  const [formCallBack, setformCallBack] = useState({
    username: { inputValue: '' },
    password: { inputValue: '' },
  })
  const clearForm = () => {
    setformCallBack({
      username: { inputValue: '' },
      password: { inputValue: '' },
    })
  }

  const initialValues: FormValues = {
    username: '',
    password: '',
  }
  const getFieldValues = (
    props: FormikProps<FormValues>,
    status: ShieldFieldStatus,
    fieldName: string
  ) => {
    // copy of current object
    let dataFromUI: any = { ...formCallBack }
    // update variable with current status of each input
    dataFromUI[fieldName] = status
    // update state from copy variable
    setformCallBack(dataFromUI)

    let eventObject = {
      target: {
        name: fieldName,
        value: status.inputValue,
      },
    }

    props.handleChange(eventObject) ||
      (status.event === 'onChange' && props.handleChange(eventObject))
    return [status.inputValue]
  }
  // Alert message to render for errors
  const [alertMessage, setAlertMessage] = useState('')

  const router = useRouter()

  const handleErrorCount = (state: any, callbackValues: any) => {
    let errors = { ...state }
    let key: string
    let value: any
    for ([key, value] of Object.entries(callbackValues)) {
      if (
        (key === 'username' &&
          typeof value.inputValue === 'string' &&
          value.inputValue !== '' &&
          (value.errorCode === 0 ||
            value.errorCode === null ||
            value.errorCode === undefined)) ||
        (key === 'password' &&
          typeof value.inputValue === 'string' &&
          value.inputValue !== '' &&
          (value.errorCode === 0 ||
            value.errorCode === null ||
            value.errorCode === undefined))
      ) {
        errors[key] = 0
      } else {
        errors[key] = state[key] + 1
      }
    }
    // update the error count to show inline errors
    return errors
  }

  // State for tracking dynamicErrorCount for each input
  const [errorCount] = useReducer(handleErrorCount, {
    username: 0,
    password: 0,
  })

  const numberOfErrorsRef = useRef(0)
  numberOfErrorsRef.current = Object.entries(errorCount as []).filter(
    (i) => i[1] > 0
  ).length

  const toggleSubmitButton = (flag: boolean = false) => {
    const loginButton: any = document.querySelector<HTMLElement>(
      `button[name="login_button"]`
    )

    if (loginButton) {
      loginButton.disabled = flag
    }
  }

  const [loginAttemptsCount, setLoginAttemptsCount] = useState(0)
  const [attemptFlag, setAttemptFlag] = useState(false)
  let mins = 2
  let now = new Date().getTime() as any
  let loginAttempts: any
  if (typeof window !== 'undefined') {
    loginAttempts = localStorage.getItem('loginAttempts') || null
  }

  useEffect(() => {
    if (loginAttempts !== null) {
      setAttemptFlag(true)
      setLoginAttemptsCount(3)
    }
  }, [loginAttempts])

  useEffect(() => {
    if (loginAttemptsCount >= 3) {
      if (loginAttempts == null) {
        localStorage.setItem('loginAttempts', now)
        setAttemptFlag(true)
      } else if (now - loginAttempts > mins * 60 * 1000) {
        setAttemptFlag(false)
        localStorage.clear()
        localStorage.removeItem('loginAttempts')
        if (loginAttemptsCount % 3 == 1) {
          setLoginAttemptsCount(1)
        } else {
          setLoginAttemptsCount(0)
        }
      }
    }
  }, [loginAttempts, loginAttemptsCount, attemptFlag, now, mins])

  const loginErrorHandler = (errorMsg: string) => {
    const loginErrorNotification: any = parse(errorMsg)
    setLoginAttemptsCount(loginAttemptsCount + 1)
    setAlertMessage(loginErrorNotification)
    enableErrorNotification()
    toggleSubmitButton()
  }

  const loginSuccessHandler = async () => {
    toggleSubmitButton()
    const sessionData = await getSession()
    hideOverlay()
    handleRouting(sessionData)
    showSuccessToast(pageData.loginPage.successMsg)
    Tealium.loginFormSuccessfulDelivery()
  }
  //Hide overlay for model
  const hideOverlay = () => {
    if (overlayDarkModeClass) {
      const overlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
      if (overlay) {
        overlay.classList.remove(overlayDarkModeClass)
      }
    }
  }
  /* NOTE:
  callback url functionality is required in the future when the user enters a protected page
  and is redirected to the login page for authentication, then redirected back to the protected page
  using the callback key stored in router.query
 */
  const handleRouting = (sessionData: any) => {
    if (router.asPath.includes('/search?searchTermQuery=')) {
      handleSearchRouting()
    } else {
      handleDefaultRouting(sessionData)
    }
  }

  const handleSearchRouting = () => {
    router.push(router.asPath + 'userLoggedIn', router.asPath)
  }

  const handleDefaultRouting = (sessionData: any) => {
    const path = router.asPath.split('=')
    const callBackUrl: string | undefined = path && path.length ? path[1] : ''
    if (sessionData?.decodedAccessToken?.isTOUAccepted === false) {
      router.push('/accept-terms-of-use')
    } else {
      handleDashboardRouting(callBackUrl)
    }
  }

  const handleDashboardRouting = (callBackUrl: string | undefined) => {
    if (process.env.FEATURE_DEVELOPER_DASHBOARD === 'false') {
      router.push(callBackUrl ? decodeURIComponent(callBackUrl) : '/')
    } else {
      router.push(callBackUrl ? decodeURIComponent(callBackUrl) : '/dashboard')
    }
  }
  const handleSubmit = async (values: FormValues) => {
    setAlertMessage('')
    let currentTime = new Date().getTime() as any
    let loginAttempts = (await localStorage.getItem('loginAttempts')) as any
    let errorMessages: string | any

    if (
      numberOfErrorsRef.current === 0 &&
      currentTime - loginAttempts < mins * 60 * 1000
    ) {
      errorMessages = pageData.loginPage.errorMsgs.softLockErrorMsg
      setAlertMessage(errorMessages)
      enableErrorNotification()
      toggleSubmitButton()
    } else if (numberOfErrorsRef.current === 0) {
      toggleSubmitButton(true)

      const signInResult: SignInResponse | undefined = await signIn(
        'credentials',
        {
          redirect: false,
          username: values.username,
          password: values.password,
        }
      )

      if (signInResult?.ok) {
        loginSuccessHandler()
      }

      if (signInResult?.error) {
        loginErrorHandler(signInResult.error)
      }
    }
    clearForm()
  }

  useEffect(() => {
    if (userLoginWrapper?.current) {
      const inputElement = userLoginWrapper?.current.querySelector(
        '#input_username'
      ) as HTMLInputElement

      if (inputElement) {
        inputElement.focus()
      }
    }
  }, [])

  const loginModalClass = !featureRegistrationOptimization
    ? loginStyles.modal_loginUser
    : loginStyles.elavonModalUIStyles
  const loginClass = !featureRegistrationOptimization
    ? loginStyles.loginPassword
    : loginStyles.elavonLoginUIStyles

  const createSignUpButton = (wrapperClassName: string): React.ReactNode => {
    return (
      <div className={wrapperClassName}>
        <USBLink
          linkType="basic"
          addClasses={loginStyles.notRegisteredUser}
          href={pageData.loginPage.form.signUp.link}
          dataTestId={'signupLink'}
        >
          {pageData.loginPage.form.signUp.label}
        </USBLink>
      </div>
    )
  }

  return (
    <div className={loginStyles.loginForm} data-testid={'loginForm'}>
      {alertMessage?.length > 0 && (
        <USBNotification
          status="danger"
          id="register_fail"
          dataTestId="login-error-notification"
          iconAssistiveText={{ label: 'Error' }}
          roleType="alert"
          notificationData={[
            {
              text: alertMessage,
              linkText: null,
              link: null,
            },
          ]}
          addClasses={loginStyles.notification}
        />
      )}
      <h1 className="page-heading">{pageData.loginPage.title}</h1>
      <p>{pageData.loginPage.description}</p>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(props) => {
          return (
            <Form noValidate>
              <div
                className={
                  loginModal
                    ? loginStyles.modal_loginUser
                    : loginStyles.loginUser
                }
              >
                <Field
                  component={USBTextInput}
                  inputName={loginModal ? 'login_modal_username' : 'username'}
                  labelText={pageData.loginPage.form.username.label}
                  statusUpdateCallback={(status: ShieldFieldStatus) => {
                    getFieldValues(props, status, 'username')
                  }}
                  errorMessages={{
                    default: pageData.loginPage.errorMsgs.username,
                  }}
                  dynamicErrorCount={errorCount.username}
                  dynamicValue={formCallBack.username.inputValue}
                  callbackFrequency="every"
                  dataTestId={'username'}
                  forwardRef={userLoginWrapper}
                />
              </div>
              <div className={loginModal ? loginModalClass : loginClass}>
                <Field
                  type="password"
                  name="password"
                  inputName={
                    loginModal ? 'login_modal_password-input' : 'password-input'
                  }
                  component={USBPasswordInput}
                  labelText={pageData.loginPage.form.password.label}
                  statusUpdateCallback={(status: ShieldFieldStatus) => {
                    getFieldValues(props, status, 'password')
                  }}
                  errorMessages={{
                    default: pageData.loginPage.errorMsgs.password,
                  }}
                  dynamicErrorCount={errorCount.password}
                  dynamicValue={formCallBack.password.inputValue}
                  passwordType="new"
                  callbackFrequency="every"
                  dataTestId={'password'}
                />
              </div>
              {featureRegistrationOptimization && (
                <div className={loginStyles.elavonAccountRecoveryUIStyles}>
                  <USBLink
                    addClasses={loginStyles.passwordRecoveryUIStyles}
                    href={pageData.loginPage.form.forgotPassword.link}
                    dataTestId={'forgotPasswordLink'}
                  >
                    {pageData.loginPage.form.forgotPassword.label}
                  </USBLink>
                </div>
              )}
              <div>
                <USBButton
                  type="submit"
                  ctaStyle="standard"
                  emphasis="heavy"
                  size="medium"
                  disabled={props.isSubmitting}
                  ariaLabel={pageData.loginPage.form.login.label}
                  addClasses={loginStyles.loginButton}
                  name={'login_button'}
                >
                  <div>
                    {props.isSubmitting && <ReactLoadingInline />}
                    {pageData.loginPage.form.login.label}
                  </div>
                </USBButton>
              </div>
              {featureRegistrationOptimization &&
                createSignUpButton(loginStyles.elavonButtonSignUpUIStyles)}
            </Form>
          )
        }}
      </Formik>
      {!featureRegistrationOptimization && (
        <>
          <div className={loginStyles.accountRecoveryUIStyles}>
            <USBLink
              linkType="arrow"
              addClasses={loginStyles.passwordRecoveryUIStyles}
              href={pageData.loginPage.form.forgotPassword.link}
              dataTestId={'forgotPasswordLink'}
            >
              {pageData.loginPage.form.forgotPassword.label}
            </USBLink>
          </div>
          <div className={loginStyles.accountRecoveryUIStyles}>
            <USBLink
              linkType="arrow"
              addClasses={loginStyles.usernameRecoveryUIStyles}
              href={pageData.loginPage.form.forgotUsername.link}
              dataTestId={'forgotUsernameLink'}
            >
              {pageData.loginPage.form.forgotUsername.label}
            </USBLink>
          </div>
          {createSignUpButton(loginStyles.buttonSignUpUIStyles)}
        </>
      )}
    </div>
  )
}
