import pageHeaderStyles from '@/components/PageHeader/pageHeader.module.scss';
import desktopStyles from '@/components/PageHeader/desktopNavBar.module.scss';

/**
 * Toggles the visibility of the overlay and menu components.
 *
 * @param profileMenuArrowFlag - A boolean flag indicating the state of the profile menu arrow.
 * @param setProfileMenuArrowFlag - A function to update the profile menu arrow flag.
 * @param listMainMenu - A reference to the main menu list element.
 * @param loginButtonRef - A reference to the login button element.
 *
 * This function performs the following actions:
 * - Toggles the profile menu arrow flag if it is currently true.
 * - Removes the dark mode class from the main menu overlay if it exists.
 * - Hides all grand submenus.
 * - Removes the visibility class from all submenus.
 * - Removes the up arrow class from all parent menu items.
 * - Removes the dropdown visibility class from all profile dropdowns.
 * - Removes the login modal visibility class from all login modals and focuses the login button.
 */
export const toggleOverlayAndMenuVisibility = (
  profileMenuArrowFlag: boolean,
  setProfileMenuArrowFlag: (flag: boolean) => void,
  listMainMenu: React.RefObject<HTMLUListElement>,
  loginButtonRef: React.RefObject<HTMLButtonElement>
): void => {
  if (profileMenuArrowFlag) setProfileMenuArrowFlag(!profileMenuArrowFlag);
  const mainMenu: any = document.querySelector<HTMLElement>(`.showOverlay`);
  if (mainMenu) {
    mainMenu.classList.remove(pageHeaderStyles.darkMode);
  }
  const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`);
  const grandSubMenu: any = document.querySelectorAll<HTMLElement>(`.grandSubMenu`);
  const profileDropdown: any = document.querySelectorAll<HTMLElement>(`.profileDropdown`);
  for (const element of grandSubMenu) {
    element.style.display = 'none';
  }
  for (const subMenus of subMenu) {
    subMenus.classList.remove(pageHeaderStyles.isVisible);
  }
  const parentMenuList = listMainMenu.current as any;
  if (parentMenuList) {
    const menuList = parentMenuList.querySelectorAll('li');
    for (const parentMenu of menuList) {
      parentMenu.classList.remove(pageHeaderStyles.upArrow);
    }
  }
  for (const dropdown of profileDropdown) {
    if (dropdown.classList.contains(pageHeaderStyles.showDropdown)) {
      dropdown.classList.remove(pageHeaderStyles.showDropdown);
    }
  }
  const loginModal: any = document.querySelectorAll<HTMLElement>(`.loginModal`);
  for (const modal of loginModal) {
    if (modal.classList.contains(pageHeaderStyles.showLoginModal)) {
      modal.classList.remove(pageHeaderStyles.showLoginModal);
      let loginButton = loginButtonRef.current as any;
      loginButton.focus();
    }
    if (modal.classList.contains(desktopStyles.showLoginModal)) {
      modal.classList.remove(desktopStyles.showLoginModal);
    }
  }
};
