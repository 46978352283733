import { hideOverlayAndMenu } from '@/components/PageHeader/components/HideOverlayAndMenu'
import {
  AuthContext,
  OverlayContext,
  OverlayContextProps,
  ProductContext,
  RenderFourthLevelMenuItemProps,
  RenderThirdLevelMenuItemProps,
  RenderThirdLevelMenuProps,
  RenderThirdLevelLinkProps,
} from '@/components/PageHeader/components/utils/renderThirdLevelMenu.model'
import styles from '@/components/PageHeader/desktopNavBar.module.scss'
import { PageHeaderMenuItem } from '@/components/PageHeader/pageHeader.model'
import { getLastStepupDuration } from '@/utils/auth-widget/auth-widget'
import { fetchAccessibleProductLink } from '@/utils/products/global-element-product-access-check'
import Link from 'next/link'

/**
 * Determines whether the link should be modified based on the authentication context.
 *
 * @param {AuthContext} authContext - The authentication context containing feature flags, session information, and widget configuration data.
 * @returns {boolean} - Returns true if the link should be modified, otherwise false.
 */
const shouldModifyLink = (authContext: AuthContext) => {
  const { featureMFA, session, authWidgetConfigData } = authContext
  return (
    featureMFA &&
    session &&
    getLastStepupDuration(
      authWidgetConfigData.MFA_WIDGET_CHALLENGE_INTERVAL_SECONDS
    )
  )
}

/**
 * Modifies the provided menu link based on the Multi-Factor Authentication (MFA) status.
 *
 * @param {AuthContext} authContext - The authentication context containing MFA status.
 * @param {string} menuLink - The original menu link to be potentially modified.
 * @returns {string} - The modified or original menu link.
 */
export const checkMFAAndModifyLink = (authContext: AuthContext, menuLink: string) => {
  if (shouldModifyLink(authContext)) {
    if (menuLink == '/dashboard/usage') {
      menuLink = ''
    }
  }
  return menuLink
}

/**
 * Renders a fourth-level menu item within a list element.
 *
 * @param {Object} params - The parameters for rendering the fourth-level menu item.
 * @param {Object} params.fourthLevelMenuItem - The fourth-level menu item to be rendered.
 * @param {number} params.fourthLevelItemIndex - The index of the fourth-level menu item.
 * @param {string} params.subMenuLink - The link for the submenu item.
 * @param {Object} params.overlayContext - The context for the overlay.
 * @param {boolean} params.overlayContext.isProfileMenuArrowVisible - Flag indicating if the profile menu arrow is visible.
 * @param {Function} params.overlayContext.toggleProfileMenuArrowVisibility - Function to toggle the visibility of the profile menu arrow.
 * @param {Function} params.overlayContext.setOverlayVisibility - Function to set the visibility of the overlay.
 * @param {Object} params.overlayContext.loginButtonRef - Reference to the login button.
 * @param {number} params.overlayContext.renderCounter - Counter for rendering.
 * @param {Array} params.overlayContext.listMainMenu - List of main menu items.
 *
 * @returns {JSX.Element} The rendered fourth-level menu item.
 */
const renderFourthLevelMenuItem = ({
  fourthLevelMenuItem,
  fourthLevelItemIndex,
  subMenuLink,
  overlayContext,
}: RenderFourthLevelMenuItemProps & {
  overlayContext: OverlayContextProps & OverlayContext
}) => {
  const {
    isProfileMenuArrowVisible,
    toggleProfileMenuArrowVisibility,
    setOverlayVisibility,
    loginButtonRef,
    renderCounter,
    listMainMenu,
  } = overlayContext

  if (subMenuLink) {
    return (
      <li key={`${fourthLevelItemIndex}-${renderCounter}`}>
        <Link href={`${subMenuLink}` || '#'}>
          <a
            className={styles.greatGrandSubMenuLink}
            onClick={() => {
              hideOverlayAndMenu({
                profileMenuArrowFlag: isProfileMenuArrowVisible,
                setProfileMenuArrowFlag: toggleProfileMenuArrowVisibility,
                setOverlayRendered: () => setOverlayVisibility(false),
                loginButtonRef,
                listMainMenu,
              })
            }}
            href={`${subMenuLink}` || '#'}
          >
            {fourthLevelMenuItem.value}
          </a>
        </Link>
      </li>
    )
  }
}

/**
 * Extracts details from a given URL string to determine the sub-menu path depth,
 * version, and product slug.
 *
 * @param {string} url - The URL string to be parsed.
 * @returns {Object} An object containing the following properties:
 * - `subMenuPathDepth` (number): The depth of the sub-menu path based on the number of segments in the URL.
 * - `subMenuVersion` (string): The version segment of the sub-menu path, typically the last segment.
 * - `subMenuProductSlug` (string): The product slug segment of the sub-menu path, typically the second to last segment.
 */
const getSubMenuDetails = (url: string) => {
  const subMenu = url?.split('/')
  const subMenuPathDepth = subMenu?.length || 0
  const subMenuVersion = subMenu ? subMenu[subMenu.length - 1] : ''
  const subMenuProductSlug = subMenu ? subMenu[subMenu.length - 2] : ''
  return { subMenuPathDepth, subMenuVersion, subMenuProductSlug }
}

/**
 * Retrieves the appropriate submenu link for a given fourth-level menu item.
 *
 * @param fourthLevelMenuItem - The menu item at the fourth level of the menu hierarchy.
 * @param overlayContext - The context containing overlay-related information.
 * @returns The URL of the submenu link. If the context is not Elavon and the submenu path depth is greater than 3,
 *          it fetches an accessible product link based on the submenu details. Otherwise, it returns the URL of the fourth-level menu item.
 */
const getSubMenuLink = (
  fourthLevelMenuItem: PageHeaderMenuItem,
  overlayContext: OverlayContext
) => {
  const { isElavon, productVersionsInfo, locale } = overlayContext
  const { subMenuPathDepth, subMenuVersion, subMenuProductSlug } =
    getSubMenuDetails(fourthLevelMenuItem?.url || '')

  if (!isElavon && subMenuPathDepth > 3) {
    return fetchAccessibleProductLink(
      subMenuVersion,
      subMenuProductSlug,
      productVersionsInfo,
      locale
    )
  }
  return fourthLevelMenuItem?.url || ''
}

/**
 * Renders the fourth level menu items for a given third level menu item.
 *
 * @param thirdLevelMenuItem - The third level menu item containing potential submenus.
 * @param overlayContext - The context for overlay operations.
 * @returns An array of rendered fourth level menu items or null if there are no submenus.
 */
const renderFourthLevelMenuItems = (
  thirdLevelMenuItem: PageHeaderMenuItem,
  overlayContext: OverlayContext
) => {
  if (!thirdLevelMenuItem?.hasSubMenus) return null

  return thirdLevelMenuItem?.menuItems?.items?.map(
    (fourthLevelMenuItem: PageHeaderMenuItem, fourthLevelItemIndex: number) => {
      const subMenuLink = getSubMenuLink(fourthLevelMenuItem, overlayContext)
      return renderFourthLevelMenuItem({
        fourthLevelMenuItem,
        fourthLevelItemIndex,
        subMenuLink,
        overlayContext,
      })
    }
  )
}

/**
 * Determines whether an accessible product link should be fetched based on the provided parameters.
 *
 * @param {PageHeaderMenuItem} thirdLevelMenuItem - The menu item at the third level.
 * @param {number} menuPathDepth - The depth of the menu path.
 * @param {string} menuVersion - The version of the menu.
 * @param {string} menuProductSlug - The product slug of the menu.
 * @returns {boolean} - Returns true if the accessible product link should be fetched, otherwise false.
 */
const shouldFetchAccessibleProductLink = (
  thirdLevelMenuItem: PageHeaderMenuItem,
  menuPathDepth: number,
  menuVersion: string,
  menuProductSlug: string
) => {
  return (
    thirdLevelMenuItem.value &&
    menuPathDepth - 1 > 2 &&
    menuVersion &&
    menuProductSlug
  )
}

/**
 * Extracts menu details from a given URL.
 *
 * @param {string} url - The URL from which to extract menu details.
 * @returns {Object} An object containing the following properties:
 * - `menuPathDepth` (number): The depth of the menu path.
 * - `menuVersion` (string): The version of the menu, extracted from the last segment of the URL.
 * - `menuProductSlug` (string): The product slug of the menu, extracted from the second to last segment of the URL.
 */
const extractMenuDetails = (url: string) => {
  const menu = url?.split('/')
  const menuPathDepth = menu?.length || 0
  const menuVersion = menu ? menu[menu.length - 1] : ''
  const menuProductSlug = menu ? menu[menu.length - 2] : ''
  return { menuPathDepth, menuVersion, menuProductSlug }
}

/**
 * Fetches the accessible product link if needed based on the provided menu item and details.
 *
 * @param menuItem - The menu item for which the link needs to be fetched.
 * @param menuDetails - An object containing details about the menu path depth, version, and product slug.
 * @param menuDetails.menuPathDepth - The depth of the menu path.
 * @param menuDetails.menuVersion - The version of the menu.
 * @param menuDetails.menuProductSlug - The slug of the menu product.
 * @param productVersionsInfo - Information about the product versions.
 * @param locale - The locale for which the link needs to be fetched.
 * @returns The URL of the menu item or the fetched accessible product link if needed.
 */
const fetchLinkIfNeeded = (
  menuItem: PageHeaderMenuItem,
  menuDetails: {
    menuPathDepth: number
    menuVersion: string
    menuProductSlug: string
  },
  productVersionsInfo: any,
  locale: string
) => {
  const { menuPathDepth, menuVersion, menuProductSlug } = menuDetails
  if (
    menuItem.url &&
    shouldFetchAccessibleProductLink(
      menuItem,
      menuPathDepth,
      menuVersion,
      menuProductSlug
    )
  ) {
    if (productVersionsInfo) {
      return fetchAccessibleProductLink(
        menuVersion,
        menuProductSlug,
        productVersionsInfo,
        locale
      )
    }
  }
  return menuItem.url
}

/**
 * Generates a menu link based on the provided context and menu item.
 *
 * @param {ProductContext} context - The context containing information about the product, including:
 *   - isElavon: A boolean indicating if the product is Elavon.
 *   - productVersionsInfo: Information about the product versions.
 *   - locale: The locale information.
 * @param {PageHeaderMenuItem} menuItem - The menu item for which the link is to be generated.
 * @returns {string} The generated menu link. If `isElavon` is true, returns the menu item's URL or an empty string.
 * Otherwise, extracts menu details and fetches the link if needed, or returns an empty string.
 */
const getMenuLink = (
  { isElavon, productVersionsInfo, locale }: ProductContext,
  menuItem: PageHeaderMenuItem
) => {
  if (isElavon) return menuItem.url || ''

  const menuDetails = extractMenuDetails(menuItem.url || '')
  return (
    fetchLinkIfNeeded(menuItem, menuDetails, productVersionsInfo, locale) || ''
  )
}

/**
 * Renders a third-level menu link and its nested fourth-level menu items.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.menuLink - The URL for the third-level menu link.
 * @param {Object} props.thirdLevelMenuItem - The third-level menu item data.
 * @param {number} props.thirdLevelItemIndex - The index of the third-level menu item.
 * @param {Object} props.overlayContext - The context object containing overlay-related states and functions.
 * @param {boolean} props.overlayContext.isProfileMenuArrowVisible - Flag indicating if the profile menu arrow is visible.
 * @param {Function} props.overlayContext.toggleProfileMenuArrowVisibility - Function to toggle the visibility of the profile menu arrow.
 * @param {Function} props.overlayContext.setOverlayVisibility - Function to set the visibility of the overlay.
 * @param {Object} props.overlayContext.loginButtonRef - Reference to the login button element.
 * @param {number} props.overlayContext.renderCounter - Counter to force re-rendering.
 * @param {Array} props.overlayContext.listMainMenu - List of main menu items.
 *
 * @returns {JSX.Element} The rendered third-level menu link and its nested fourth-level menu items.
 */
const renderThirdLevelLink = ({
  menuLink,
  thirdLevelMenuItem,
  thirdLevelItemIndex,
  overlayContext,
}: RenderThirdLevelLinkProps) => {
  const {
    isProfileMenuArrowVisible,
    toggleProfileMenuArrowVisibility,
    setOverlayVisibility,
    loginButtonRef,
    renderCounter,
    listMainMenu,
  } = overlayContext

  const thirdLevelLink = (
    <a
      className={styles.grandSubMenuLink}
      onClick={() => {
        hideOverlayAndMenu({
          profileMenuArrowFlag: isProfileMenuArrowVisible,
          setProfileMenuArrowFlag: toggleProfileMenuArrowVisibility,
          setOverlayRendered: () => setOverlayVisibility(false),
          loginButtonRef,
          listMainMenu,
        })
      }}
    >
      {thirdLevelMenuItem.value}
    </a>
  )

  return (
    <li key={`${thirdLevelItemIndex}-${renderCounter}`}>
      {menuLink && <Link href={`${menuLink}` || '#'}>{thirdLevelLink}</Link>}
      {!menuLink && <>{thirdLevelLink}</>}
      <ul
        className={`${menuLink} ${styles.fourthLevelGrandSubMenuLinkWrapper}`}
      >
        {renderFourthLevelMenuItems(thirdLevelMenuItem, overlayContext)}
      </ul>
    </li>
  )
}

/**
 * Renders a third-level menu item.
 *
 * @param {Object} params - The parameters for rendering the third-level menu item.
 * @param {Object} params.thirdLevelMenuItem - The third-level menu item to be rendered.
 * @param {number} params.thirdLevelItemIndex - The index of the third-level menu item.
 * @param {Object} params.context - The context containing various configurations and states.
 * @param {boolean} params.context.isElavon - Indicates if the Elavon feature is enabled.
 * @param {boolean} params.context.featureMFA - Indicates if the MFA feature is enabled.
 * @param {Object} params.context.session - The session information.
 * @param {Object} params.context.authWidgetConfigData - The authentication widget configuration data.
 * @param {Object} params.context.overlayContext - The overlay context containing additional configurations.
 * @param {Object} params.context.overlayContext.productVersionsInfo - Information about product versions.
 * @param {string} params.context.overlayContext.locale - The locale information.
 * @param {boolean} params.context.overlayContext.isProfileMenuArrowVisible - Indicates if the profile menu arrow is visible.
 * @param {Function} params.context.overlayContext.toggleProfileMenuArrowVisibility - Function to toggle the visibility of the profile menu arrow.
 * @param {Function} params.context.overlayContext.setOverlayVisibility - Function to set the visibility of the overlay.
 * @param {Array} params.context.overlayContext.mainMenuItems - The main menu items.
 * @param {Object} params.context.overlayContext.loginButtonRef - Reference to the login button.
 * @param {number} params.context.overlayContext.renderCounter - Counter for rendering.
 * @param {Function} params.context.overlayContext.listMainMenu - Function to list the main menu.
 * @param {Object} params.context.secondLevelMenuItem - The second-level menu item.
 *
 * @returns {JSX.Element | undefined} The rendered third-level menu item link, or undefined if no link is available.
 */
const renderThirdLevelMenuItem = ({
  thirdLevelMenuItem,
  thirdLevelItemIndex,
  context,
}: RenderThirdLevelMenuItemProps) => {
  const {
    isElavon,
    featureMFA,
    session,
    authWidgetConfigData,
    overlayContext,
  } = context

  const authContext = { featureMFA, session, authWidgetConfigData }
  const productContext = {
    isElavon,
    productVersionsInfo: overlayContext.productVersionsInfo,
    locale: overlayContext.locale,
  }

  let menuLink = getMenuLink(productContext, thirdLevelMenuItem)
  menuLink = checkMFAAndModifyLink(authContext, menuLink || '')

  if (menuLink) {
    return renderThirdLevelLink({
      menuLink,
      thirdLevelMenuItem,
      thirdLevelItemIndex,
      overlayContext: {
        isProfileMenuArrowVisible: overlayContext.isProfileMenuArrowVisible,
        toggleProfileMenuArrowVisibility: overlayContext.toggleProfileMenuArrowVisibility,
        setOverlayVisibility: overlayContext.setOverlayVisibility,
        mainMenuItems: overlayContext.mainMenuItems,
        loginButtonRef: overlayContext.loginButtonRef,
        isElavon: overlayContext.isElavon,
        productVersionsInfo: overlayContext.productVersionsInfo,
        locale: overlayContext.locale,
        renderCounter: overlayContext.renderCounter,
        listMainMenu: overlayContext.listMainMenu,
      },
      secondLevelMenuItem: context.secondLevelMenuItem,
      isElavon,
      featureMFA,
      session,
      authWidgetConfigData,
    })
  }
}

/**
 * Renders the third level menu items for a given second level menu item.
 *
 * @param {Object} params - The parameters for rendering the third level menu.
 * @param {PageHeaderMenuItem} params.secondLevelMenuItem - The second level menu item containing submenus.
 * @param {RenderThirdLevelMenuProps} [params.context] - The context for rendering the menu, including overlay context and other configurations.
 * @param {boolean} [params.context.isElavon=false] - Flag indicating if the Elavon context is active.
 * @param {boolean} [params.context.featureMFA=false] - Flag indicating if the MFA feature is enabled.
 * @param {any} [params.context.session=null] - The current session information.
 * @param {Object} [params.context.authWidgetConfigData={}] - Configuration data for the authentication widget.
 * @param {OverlayContext} [params.context.overlayContext] - The overlay context containing various UI state and handlers.
 * @param {boolean} [params.context.overlayContext.isProfileMenuArrowVisible=false] - Flag indicating if the profile menu arrow is visible.
 * @param {Function} [params.context.overlayContext.toggleProfileMenuArrowVisibility] - Function to toggle the visibility of the profile menu arrow.
 * @param {Function} [params.context.overlayContext.setOverlayVisibility] - Function to set the visibility of the overlay.
 * @param {any} [params.context.overlayContext.mainMenuItems=null] - The main menu items.
 * @param {Object} [params.context.overlayContext.loginButtonRef={ current: null }] - Reference to the login button.
 * @param {boolean} [params.context.overlayContext.isElavon=false] - Flag indicating if the Elavon context is active within the overlay context.
 * @param {Object} [params.context.overlayContext.productVersionsInfo={}] - Information about product versions.
 * @param {string} [params.context.overlayContext.locale=''] - The current locale.
 * @param {number} [params.context.overlayContext.renderCounter=0] - Counter for the number of renders.
 * @param {any} [params.context.overlayContext.listMainMenu=null] - The list of main menu items.
 * @param {boolean} [params.context.isProfileMenuArrowVisible=false] - Flag indicating if the profile menu arrow is visible.
 *
 * @returns {JSX.Element} The rendered third level menu as an unordered list.
 */
export const renderThirdLevelMenu = ({
  secondLevelMenuItem,
  context = {
    isElavon: false,
    featureMFA: false,
    session: null,
    authWidgetConfigData: {},
    overlayContext: {
      isProfileMenuArrowVisible: false,
      toggleProfileMenuArrowVisibility: () => {},
      setOverlayVisibility: () => {},
      mainMenuItems: null,
      loginButtonRef: { current: null },
      isElavon: false,
      productVersionsInfo: {},
      locale: '',
      renderCounter: 0,
      listMainMenu: null,
    },
    isProfileMenuArrowVisible: false,
  },
}: RenderThirdLevelMenuProps) => {
  const {
    overlayContext = {
      isProfileMenuArrowVisible: false,
      toggleProfileMenuArrowVisibility: () => {},
      setOverlayVisibility: () => {},
      mainMenuItems: null,
      loginButtonRef: { current: null },
      isElavon: false,
      productVersionsInfo: {},
      locale: '',
      renderCounter: 0,
      listMainMenu: null,
    },
  } = context

  return (
    <ul className={`${styles.grandSubMenuList} ${styles.drop} grandSubMenu`}>
      {secondLevelMenuItem?.hasSubMenus &&
        secondLevelMenuItem?.menuItems?.items?.map(function (
          thirdLevelMenuItem: PageHeaderMenuItem,
          thirdLevelItemIndex: number
        ) {
          return renderThirdLevelMenuItem({
            thirdLevelMenuItem,
            thirdLevelItemIndex,
            context: { ...context, overlayContext, secondLevelMenuItem },
          })
        })}
    </ul>
  )
}
