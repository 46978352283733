import '@usb-shield/react-grid/dist/library/styles/index.css'

import Link from 'next/link'
import USBButton from '@usb-shield/react-button'
import USBLink from '@usb-shield/react-link'
import styles from '../desktopNavBar.module.scss'

export const ProfileDropdownMenu = ({ hideOverlayAndMenu, onLogoutClick, profileMenuArrowFlag, setProfileMenuArrowFlag, setOverlayRendered, listMainMenu, loginButtonRef }: {
  hideOverlayAndMenu: any,
  onLogoutClick: any,
  profileMenuArrowFlag: boolean,
  setProfileMenuArrowFlag: (flag: boolean) => void,
  setOverlayRendered: (status: boolean) => void,
  listMainMenu: React.RefObject<any>,
  loginButtonRef: React.RefObject<any>
}) => {
  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'

  const handleHideOverlayAndMenu = () => {
    
    if (typeof setOverlayRendered === 'function') {
      hideOverlayAndMenu({
      profileMenuArrowFlag,
      setProfileMenuArrowFlag,
      setOverlayRendered,
      listMainMenu,
      loginButtonRef
      });
    }
  };

  return (
    <div>
      {(process.env.SITE_BRAND !== 'elavon') && (process.env.FEATURE_SANDBOX_PROJECTS !== "false") &&
        <USBLink>
          <Link href='/credentials'>
            <div onClick={handleHideOverlayAndMenu}>Credentials</div>
          </Link>
        </USBLink>
      }

      {(process.env.SITE_BRAND !== 'elavon') && (process.env.FEATURE_SUPPORT_TICKET !== 'false') &&
        <USBLink>
          <Link href='/support'>
            <div onClick={handleHideOverlayAndMenu}>
              Support Tickets
            </div>
          </Link>
        </USBLink>
      }

      {isElavon && (
        <USBLink>
          <Link
            href={
              process.env.FEATURE_DEVELOPER_DASHBOARD !== 'false'
                ? '/dashboard'
                : '/support'
            }
          >
            Dashboard
          </Link>
        </USBLink>
      )}

      <USBLink>
        <Link href='/profile'>
          <div onClick={handleHideOverlayAndMenu}>{!isElavon ? 'Account Profile' : 'Account'}</div>
        </Link>
      </USBLink>
      <USBButton
        ctaStyle="standard"
        emphasis="heavy"
        size='medium'
        handleClick={onLogoutClick}
        id='logoff-link'
        addClasses={styles.logOffLink}
        dataTestId='onLogoutClick'
      >
        <span className={isElavon ? styles.redLogout : ''}>Log Out</span>
      </USBButton>
    </div>
  )
}
