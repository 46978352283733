import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { RegionPickerProps } from '@/components/RegionPicker/RegionPicker.model'
import styles from '@/components/RegionPicker/regionPicker.module.scss'
import { getCookies, setCookie } from 'cookies-next'
import { setExpirationDays } from '@/utils/cookies/cookies'
import { useRouter } from 'next/router'
import axios from 'axios'
import { removePrefix, setCookies, handleProductRedirect, redirectToHomePage } from './regionpicker-utils'

const CONTENT_BASE_PATH = process.env.CONTENT_BASE_PATH
const CONTENT_DOMAIN = process.env.CONTENT_DOMAIN
const SITE_BRAND = process.env.SITE_BRAND

const selectorStyles = {
  container: (base: any, state: any) => ({
    ...base,
    padding: 0,
    border: 'none',
    borderRadius: 0,
  }),
  control: (styles: any, state: any) => ({
    ...styles,
    cursor: 'pointer',
    textAlign: 'left',
    border: 0,
    borderRadius: '0.25rem',
    boxShadow: 'none',
    outline: state.isFocused ? 'rgb(10, 65, 197) solid 2px !important' : 0,
    outlineOffset: state.isFocused ? '1px' : 0,
  }),
  option: (styles: any) => ({
    ...styles,
    cursor: 'pointer',
    textAlign: 'left',
    boxShadow: 'none',
  }),
}

const regionPickerStyledElavon = styles.regionPickerWrapper

const RegionPicker = ({
  options,
  menuIsOpen,
  classNamePrefix,
  handleMenuOpenCallback = () => {},
  handleMenuCloseCallback = () => {},
}: RegionPickerProps & { selectorStyles: any }) => {
  const { locale, countryCode } = getCookies();
  const [selectedOption, setSelectedOption] = useState(locale || process.env.DEFAULT_LOCALE);
  const [defaultValue, setDefaultValue] = useState<any[]>([]);

  const isElavon = SITE_BRAND === 'elavon';
  const router = useRouter();

  useEffect(() => {
    if (options?.length > 0) {
      const defaultVal = getDefaultOption(options, selectedOption);
      setDefaultValue(defaultVal);
    }
  }, [locale]);



  const handleTypeSelect = async (e: any) => {
    const selectedLocale = e.value;
    setSelectedOption(selectedLocale);

    const selectedCountry = removePrefix(selectedLocale);

    setCookies(selectedLocale, selectedCountry.toUpperCase());

    if (isElavon && router.asPath.includes('/products')) {
      await handleProductRedirect(router, selectedCountry, selectedLocale);
    } else {
      await redirectToHomePage(router);
    }
  };

  const getDefaultOption = (options: any, selectedOption: string) => {
    const filterList = options.map((match: any) =>
      Object.keys(match.options).length
        ? match.options.filter((option: any) => option.value === selectedOption)
        : []
    );
    return [...filterList[0], ...filterList[1] || []]
  }

  const regionPickerStyledBase = styles.regionPickerTwoTierWrapper;
  const regionPickerStyled = process.env.SITE_BRAND !== 'elavon' ? regionPickerStyledBase : regionPickerStyledElavon;

  interface RegionSelectProps {
    options: any;
    menuIsOpen: boolean;
    classNamePrefix?: string;
    handleTypeSelect: (e: any) => void;
    handleMenuOpenCallback?: (e?: any) => void;
    handleMenuCloseCallback?: (e?: any) => void;
    selectorStyles: any;
    defaultValue: any;
  }
  
  const RegionSelect = ({
    options,
    menuIsOpen,
    classNamePrefix,
    handleTypeSelect,
    handleMenuOpenCallback,
    handleMenuCloseCallback,
    selectorStyles,
    defaultValue,
  }: RegionSelectProps) => (
    <Select
      isSearchable={false}
      options={options}
      aria-label={'Select Country'}
      formatOptionLabel={(options: any) => (
        <div
          className={
            menuIsOpen ? styles.countryOptionOpen : styles.countryOption
          }
        >
          {options?.image && <img
            src={process.env.CONTENT_DOMAIN + options.image}
            alt="country-image"
            width="24"
            height="16"
          />}
          <span>{options.label}</span>
        </div>
      )}
      onChange={handleTypeSelect}
      onMenuOpen={handleMenuOpenCallback}
      onMenuClose={handleMenuCloseCallback}
      styles={selectorStyles}
      value={defaultValue}
      label="Single select"
      classNamePrefix={classNamePrefix || 'filter'}
      menuIsOpen={menuIsOpen || false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
    />
  )
  return (
    <>
      {options?.length > 0 && (
        <div className={classNamePrefix ? styles.regionPickerMobileWrapper : regionPickerStyled}>
          <RegionSelect
            options={options}
            menuIsOpen={menuIsOpen}
            classNamePrefix={classNamePrefix}
            handleTypeSelect={handleTypeSelect}
            handleMenuOpenCallback={handleMenuOpenCallback}
            handleMenuCloseCallback={handleMenuCloseCallback}
            selectorStyles={selectorStyles}
            defaultValue={defaultValue}
          />
        </div>
      )}
    </>
  )
}

export default RegionPicker