import styles from '../desktopNavBar.module.scss'
import pageHeaderStyles from '../pageHeader.module.scss'
import { removeUpArrowFromParentMenus, toggleOverlayBasedOnSubMenu, shouldRemoveOverlay } from './HeaderTierTop'
import { resetNavArrows } from './HeaderTierBottom'
import focusTrap from '@/utils/focus-trap/focus-trap'

interface ToggleProfileDetailsParams {
  e: any,
  listMainMenu: React.RefObject<HTMLUListElement>,
  setOverlayRendered: (status: boolean) => void,
  profileMenuArrowFlag: boolean,
  setProfileMenuArrowFlag: (flag: boolean) => void,
  overlayRendered: boolean
}

/**
 * Toggles the visibility of the profile details dropdown and manages the overlay state.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Event} params.e - The event that triggered the toggle.
 * @param {React.RefObject} params.listMainMenu - A reference to the main menu list element.
 * @param {Function} params.setOverlayRendered - Function to set the state of the overlay rendering.
 * @param {boolean} params.profileMenuArrowFlag - Flag indicating the state of the profile menu arrow.
 * @param {Function} params.setProfileMenuArrowFlag - Function to set the state of the profile menu arrow.
 * @param {boolean} params.overlayRendered - Flag indicating whether the overlay is currently rendered.
 *
 * @returns {void}
 */
export const toggleProfileDetails = ({
  e,
  listMainMenu,
  setOverlayRendered,
  profileMenuArrowFlag,
  setProfileMenuArrowFlag,
  overlayRendered
}: ToggleProfileDetailsParams) => {
  resetNavArrows()
  const parentMenuList = listMainMenu.current as any
  if (parentMenuList) {
    const menuList = parentMenuList.querySelectorAll('li')
    removeUpArrowFromParentMenus(menuList)
  }

  const subMenu: any = document.querySelectorAll<HTMLElement>(`.subMenu`)
  const profileDropdown: any = document.querySelector<HTMLElement>(`.profileDropdown`)
  const showOverlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
  profileDropdown.classList.toggle(styles.showDropdown)

  if (subMenu) {
    for (const subMenus of subMenu) {
      toggleOverlayBasedOnSubMenu(showOverlay, subMenus, setOverlayRendered)
      addTwoTierDarkMode(showOverlay, setOverlayRendered)
      subMenus.classList.remove(styles.isVisible)
    }
  }
  setProfileMenuArrowFlag(!profileMenuArrowFlag)
  
  focusTrap('.profileDropdown')

  if (process.env.SITE_BRAND !== 'elavon') {
    if (shouldRemoveOverlay(e.target, overlayRendered)) {
      showOverlay?.classList?.remove(pageHeaderStyles.twoTierDarkMode)
      setOverlayRendered(false)
    } else {
      showOverlay?.classList?.add(pageHeaderStyles.twoTierDarkMode)
      setOverlayRendered(true)
    }
  }
}

/**
 * Adds a two-tier dark mode class to the provided HTML element if it doesn't already have it,
 * and sets the overlay rendered status to true.
 *
 * @param {HTMLElement} showOverlay - The HTML element to which the two-tier dark mode class will be added.
 * @param {(status: boolean) => void} setOverlayRendered - A callback function to set the overlay rendered status.
 */
const addTwoTierDarkMode = (showOverlay: HTMLElement, setOverlayRendered: (status: boolean) => void) => {
  if (!showOverlay.classList.contains(pageHeaderStyles.twoTierDarkMode)) {
    showOverlay.classList.add(pageHeaderStyles.twoTierDarkMode)
    setOverlayRendered(true)
  }
}
