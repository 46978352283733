import { SignOutParams, SignOutResponse } from 'next-auth/react'
import { NextRouter } from 'next/router'
import pageHeaderStyles from '../pageHeader.module.scss'

interface OnLogoutClickParams {
  setOverlayRendered: (status: boolean) => void,
  signOut: <R extends boolean = true>(options?: SignOutParams<R> | undefined) => Promise<R extends true ? undefined : SignOutResponse>,
  router: NextRouter,
  showSuccessToast: (message: string) => void,
  queryClient: { removeQueries: () => void }
}

/**
 * Handles the logout click event.
 *
 * @param {Object} params - The parameters for the logout click handler.
 * @param {Function} params.setOverlayRendered - Function to set the overlay rendered state.
 * @param {Function} params.signOut - Function to sign out the user.
 * @param {Object} params.router - Next.js router object for navigation.
 * @param {Function} params.showSuccessToast - Function to show a success toast message.
 * @param {Object} params.queryClient - React Query client for managing queries.
 *
 * @returns {Promise<void>} A promise that resolves when the logout process is complete.
 */
export const onLogoutClick = async ({
  setOverlayRendered,
  signOut,
  router,
  showSuccessToast,
  queryClient
}: OnLogoutClickParams) => {
  const overlay: any = document.querySelector<HTMLElement>(`.showOverlay`)
  overlay.classList.remove(pageHeaderStyles.twoTierDarkMode)
  setOverlayRendered(false)
  await signOut({ callbackUrl: '/', redirect: false })
  await router.push('/')
  showSuccessToast('Logout successful')
  queryClient.removeQueries()
}
