import { getLastStepupDuration } from '@/utils/auth-widget/auth-widget'

/**
 * Enables the MFA challenge widget based on certain conditions.
 *
 * @param {any} e - The event object.
 * @param {boolean} featureMFA - Flag indicating if MFA feature is enabled.
 * @param {any} authWidgetConfigData - Configuration data for the authentication widget.
 * @param {any} session - The current session object.
 * @param {Function} setCallBackUrl - Function to set the callback URL.
 * @param {Function} setActivityName - Function to set the activity name.
 * @param {Function} setChallengeModalIsOpen - Function to set the challenge modal open state.
 * @returns {boolean} - Returns false if the MFA challenge widget is not enabled.
 */
export const enableMFAChallengeWidget = (
  e: any,
  featureMFA: boolean,
  authWidgetConfigData: any,
  session: any,
  setCallBackUrl: Function,
  setActivityName: Function,
  setChallengeModalIsOpen: Function
) => {
  const enableMFAChallengeWidget =
    featureMFA && e.target.innerText == 'Usage' && authWidgetConfigData
  if (enableMFAChallengeWidget) {
    if (
      session &&
      getLastStepupDuration(
        authWidgetConfigData.MFA_WIDGET_CHALLENGE_INTERVAL_SECONDS
      )
    ) {
      e.preventDefault()
      setCallBackUrl(e.target.href)
      setActivityName('Usage')
      setChallengeModalIsOpen(true)
    }
  }
  return false
}
