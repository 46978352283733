import { Login } from '@/components/Login/Login'
import { LoginWidget } from '@/components/LoginWidget/LoginWidget'
import { USBGrid, USBColumn } from '@usb-shield/react-grid'
import loginModalStyles from '@/modules/login-page/LoginPage.module.scss'
import USBNotification from '@usb-shield/react-notification'
import React, { useState } from 'react'
import { MFAWidgetProps } from '@/components/LoginWidget/loginWidget.model'

export const LoginModal = ({
  overlayDarkModeClass,
  authWidgetConfigData,
  mfaWidgetProps,
}: {
  overlayDarkModeClass: string
  authWidgetConfigData: any
  mfaWidgetProps: MFAWidgetProps | null
}) => {
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
  // Alert message to render for errors
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [loginWidgetIsOpen, setLoginWidgetIsOpen] = useState(true)
  const ModalLoginColumn = {
    span: null,
    spans: {
      xlarge: 16,
      large: 16,
      medium: 8,
      small: 4,
    },
    offset: {
      xlarge: 0,
      large: 0,
      medium: 0,
      small: 0,
    },
    display: 'block',
    padding: 'zero',
    align: 'start',
    justify: 'stretch',
  }

  const loginWidgetCallback = async (
    status: boolean | null,
    message: string,
    showAlert: boolean = false
  ) => {
    if (status != null) setLoginWidgetIsOpen(status)
    setShowAlert(false)
    if (showAlert) {
      setShowAlert(true)
    } else if (message != '') setAlertMessage(message)
    else setAlertMessage(message)
  }

  return (
    <>
      <div className={loginModalStyles.main} id="login-modal">
        <USBGrid padding="zero">
          <USBColumn layoutOpts={ModalLoginColumn}>
            {!featureMFA && (
              <Login
                overlayDarkModeClass={overlayDarkModeClass}
                loginModal={true}
              ></Login>
            )}
            {featureMFA && showAlert && alertMessage?.length > 0 && (
              <USBNotification
                variant="error"
                id="login_fail"
                dataTestId="login-error-notification"
                iconAssistiveText={{ label: 'Error' }}
                roleType="alert"
                notificationData={[
                  {
                    text: alertMessage,
                    linkText: null,
                    link: null,
                  },
                ]}
                addClasses={loginModalStyles.notification}
              />
            )}
            {featureMFA &&
              loginWidgetIsOpen &&
              mfaWidgetProps?.loginModalIsOpen && (
                <LoginWidget
                  overlayDarkModeClass={overlayDarkModeClass}
                  loginModal={true}
                  authWidgetConfigData={authWidgetConfigData}
                  loginWidgetCallback={loginWidgetCallback}
                  mfaWidgetProps={mfaWidgetProps}
                ></LoginWidget>
              )}
          </USBColumn>
        </USBGrid>
      </div>
      {/* MFA Auth widget css issue fix  */}
      <style>{`
        .aw-usb-authenticate {
          padding-top: 1.5rem !important;
        }
      `}</style>
      {/* MFA Auth widget css issue fix */}
    </>
  )
}
