import { useRouter } from 'next/router'

const markdownSvgSelector = '[class*="documentPageMDContent"] svg',
  markdownSvgAriaLabel = 'Mermaid SVG Image',
  searchInterval = 500,
  domSearchLimit = 20

let svgSearchInterval: any, svgSearchIndex: any

function addAriaLabelToSvgUpdates() {
  let elements = document.querySelectorAll(markdownSvgSelector)

  for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
    elements[elementIndex].setAttribute('aria-label', markdownSvgAriaLabel)
  }

  svgSearchIndex++

  if (svgSearchIndex > domSearchLimit) {
    clearInterval(svgSearchInterval)
  }
}

export const titleTagSuffix = 'Developer Portal'
export const elavonTitleTagPrefix = 'Elavon'

export function addAriaLabelToSvg() {
  svgSearchIndex = 0

  svgSearchInterval = setInterval(addAriaLabelToSvgUpdates, searchInterval)

  return true
}

export function getTitleFromRoute() {
  const isElavon: boolean = process.env.SITE_BRAND === 'elavon'
  const titleList = {
    home: !isElavon ? titleTagSuffix : `${elavonTitleTagPrefix} | Home`,
    terms: !isElavon ? `Terms` : `${elavonTitleTagPrefix} | Terms`,
    privacy: !isElavon ? `Privacy` : `${elavonTitleTagPrefix} | Privacy policy`,
    cookie_policy: !isElavon ? `Cookie policy` : `${elavonTitleTagPrefix} | Cookie policy`,
  }
  
  let router = useRouter()

  switch (router.asPath) {
    case '/accept-terms-of-use':
      return `Accept terms of use | ${titleTagSuffix}`

    case '/profile':
      return `Profile | ${titleTagSuffix}`

    case '/support/create':
      return `Support | ${titleTagSuffix}`

    case '/credentials':
      return `Credentials | ${titleTagSuffix}`

    case '/forgot-username':
      return `Forgot username | ${titleTagSuffix}`

    case '/forgot_password':
      return `Forgot password | ${titleTagSuffix}`

    case '/profile/add-user':
      return `Add user | ${titleTagSuffix}`
    
    case '/privacy':
       return titleList.privacy
    
    case '/cookie-policy':
       return titleList.cookie_policy

    case '/terms':
      return titleList.terms

    default:
      return titleList.home
  }
}